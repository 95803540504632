import React, {Component} from 'reactn';
import {Fragment} from "react";
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import i18n from "../../views/Pages/Login/i18n";
import {
    Button, Col,
    Form,
    FormGroup, FormText,
    Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Popover,
    PopoverBody, Row,
} from "reactstrap";
import {
    agreementsQuery, createRenewalAgreement,
    getAgreementStatusesQuery,
    getAgreementStatusFlowFromQuery,
    setAgreementStatusMutation,
    updateAgreementStartDateQuery,
    contractQuery, getQuoteProductNamesQuery, salesOrganizationsQuery, getUsers
} from "../../queries/Queries";
import {ApolloConsumer, ApolloProvider, Mutation, Query} from "react-apollo";
import fileDownload from "js-file-download";
import axios from "../../utils/Client";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import ExpiringAlert from "../ExpiringAlert";
import moment from "moment";
import {InMemoryCache} from "apollo-cache-inmemory";
import {setContext} from "apollo-link-context";
import {ApolloClient} from "apollo-client";
import {createHttpLink} from 'apollo-link-http';
import SelectTariffs from "../SelectTariffs";
import Loading from "../Loading";
import {SingleDatePicker} from 'react-dates';
import SalesAgentSelect from '../SalesAgentSelect/SalesAgentSelect';
import { setTariffName, BASE_PRICE_STRINGS } from "../../utils/Helpers";

//---------------------------------------------------------

class NoAgreements extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="nosalesagent">
                <i className="icon ion-android-bookmark"></i>
                <p className="text">There are no Agreements uploaded yet.</p>
            </div>
        );
    }
}

//---------------------------------------------------------
const httpLink = createHttpLink({
    uri: `${(window.config.consul.GRAPHQL_URL || window.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.token;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        }
    }
});

// Inserting additional Apollo client to make query mutations and refetching of certain queries afterwards possible
const agreementsClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: true}),
        shouldBatch: true,
        defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore',
        },
    }
});

class AgreementsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    toggleChangeStartDate = () => {
        this.setState({changeStartDateOpen: !this.state.changeStartDateOpen})
    }

    toggleStartRenewal = (state, callback) => {
        this.setState({startRenewal: !this.state.startRenewal , startDate: null })
        if (!this.state.startRenewal) {
            this.setState(this.getInitialState());
        }
    }

    toggleReasonModal = (statusToSet, setAgreementStatus, agreementId) => {
        this.setState({
            reasonModalOpen: !this.state.reasonModalOpen,
            statusToSet: statusToSet,
            setAgreementStatus,
            agreementId
        })
    }

    toggleUploadModal = (statusToSet, setAgreementStatus, agreementId, processInstanceId) => {
        const isOpen = !this.state.uploadModalOpen;
        this.setState({
            uploadModalOpen: isOpen,
            statusToSet,
            setAgreementStatus,
            agreementId,
            processInstanceId
        })
        if (!isOpen) {
            this.setState(this.getInitialState());
        }
    }

    createRenewal = (id, tariff, commissionUnitRate, commissionStandingCharge, agreementData, aq, startDate, contractDuration) => {
        agreementsClient.mutate( {
            mutation: createRenewalAgreement,
            variables: {
                agreementId: id,
                tariff: tariff,
                commissionUnitRate: commissionUnitRate,
                commissionStandingCharge: commissionStandingCharge,
                agreementData: agreementData,
                aq: aq,
                startDate: startDate,
                contractDuration: contractDuration
            },
            refetchQueries: [
                { query: getAgreementStatusFlowFromQuery, variables: { agreement: id } },
                { query: agreementsQuery },
                { query: getAgreementStatusesQuery }
            ]
        }).then(()=>{
            ReactDOM.render(<ExpiringAlert color="success"
                                       message={'Renewal started'}/>, document.getElementById('alert'));
        }).catch(() => {
            ReactDOM.render(<ExpiringAlert color="danger"
                                       message={"Renewal start failed!"}/>, document.getElementById('alert'));
        });
    }

    updateStatus = (statusToSet, agreementId) => {
        agreementsClient.mutate({
            mutation: setAgreementStatusMutation,
            variables: {
                agreementId: agreementId,
                agreementStatus: {key: statusToSet.key}
            },
            refetchQueries: [
                { query: getAgreementStatusFlowFromQuery, variables: { agreement: agreementId } },
                { query: agreementsQuery },
                { query: getAgreementStatusesQuery }
            ]
        }).then(result => this.state.statusPromiseResolve())
        .catch(error => {
            ReactDOM.render(<ExpiringAlert color="danger"
                                           message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
            console.log('There was an error changing Agreement status', error.message);}
        )
    }

    getPossibleStatuses = (agreementId) => {
        agreementsClient.query({
            query: getAgreementStatusFlowFromQuery,
            variables: {
                agreement: agreementId
            }
        });
    }

    handleRenewalModalSearch = async (apolloClient) => {
        return await apolloClient.query({
            query: salesOrganizationsQuery,
            variables: {
                pageNumber: 0,
                pageSize:100,
                where:`name=='*${this.state.query}*',businessPartnerId=='${this.state.ownerOrganizationId}'`,
                sort:{orders:[{property:'name', direction:'ASC'}]}
            }
        })
    }

    removeAttachment = (attachmentName) => {
        this.setState((prevState) => ({
            ...prevState,
            file: prevState.file.filter((file) => file.name !== attachmentName),
        }));
    }

    getInitialState = () => {
        return {
            month: moment().format('MMM-YY'),
            startDate: moment().add(1, 'day'),
            aq: '',
            advancedPrice: false,
            isHHMeter: false,
            userSearch: '',
            enabledSearch: '',
            agreementData: null,
            commissionStandingCharge: '',
            commissionUnitRate: '',
            contractDuration: '12',
            ownerUserId: '',
            ownerOrganizationId: '',
            businessPartner: [],
            file: [],
            quote: null,
            statusReason: null,
            organizationId: this.props.organizationId,
            username: this.props.username,
            agreementStartDate: moment().add(15, 'd').format('YYYY-MM-DD') + "T00:00:00Z",
            popoverOpen: false,
            continueRenewal: false,
        }
    }

    setBroker = (selectedBroker) => {
        if (selectedBroker) {
            this.setState({
                ...this.state,
                ownerOrganizationId: selectedBroker.businessPartnerId,
                agreementData: {
                    ...this.state.agreementData,
                    broker: {
                        ...this.state.agreementData.broker,
                        id: selectedBroker.businessPartnerId,
                        name: selectedBroker.name,
                        startDate: this.state.startDate.format('MM-DD-YYYY')
                    }
                }
            });
        }
    }

    setAdduserList = (e) => {
        this.setState({
            ...this.state,
            isBrokerAndSalesAgentFilledIn: true,
            ownerUserId: e.target.value,
            agreementData: {
                ...this.state.agreementData,
                broker: {
                    ...this.state.agreementData.broker,
                    aduserList: [{
                        firstName: e.target.options[e.target.selectedIndex].dataset.firstName,
                        lastName: e.target.options[e.target.selectedIndex].dataset.lastName,
                        phone: e.target.options[e.target.selectedIndex].dataset.phone,
                        email: e.target.options[e.target.selectedIndex].dataset.email
                    }]
                }
            }
        });
    }

    handleTariffNameChangeBySubmit() {
        const tariff = this.state.quote.tariffName;
        const service = this.state.quote.service;

        let newName = setTariffName(tariff, service, this.state.advancedPrice, this.state.isHHMeter);

        this.setState({
            ...this.state,
            quote: {
                ...this.state.quote,
                tariffName: {
                    ...this.state.quote.tariffName,
                    name: newName,
                    tariffCode: newName
                }
            }
        }, ()=> {

            this.createRenewal(
                this.state.agreementId,
                JSON.stringify(this.state.quote.tariffName),
                this.state.commissionUnitRate,
                this.state.commissionStandingCharge,
                JSON.stringify(this.state.agreementData),
                Number(this.state.aq),
                this.state.startDate.format("yyyy-MM-DD"),
                parseInt(this.state.contractDuration)
            );
            this.toggleStartRenewal();
        });
    }

    
    render() {
        const isAdmin = this.props.currentUser.roles.some(role => role.role.key === 'SALESADMIN');

        const agreementNumberCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.number'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).documentNumber;
            }
        };
        const dateCol = {
            dataField: 'date',
            text: i18n.t('agreement.creationDate'),
            sort: true,
            formatter: (cell, row) => new Date(cell.time).toLocaleDateString()
        };
        const clientNameCol = {
            dataField: 'clientName',
            text: i18n.t('agreement.clientName'),
            sort: true
        };
        const ownerOrganizationNameCol = {
            dataField: 'ownerOrganizationName',
            text: i18n.t('agreement.salesOrganization'),
            sort: true,

        };
        const subOwnerOrganizationCol = {
            dataField: 'subOwnerOrganizationName',
            text: i18n.t('agreement.associateOrganization'),
            sort: true,
            hidden: !this.global.permissions.includes('Quotes.ViewSubbrokerColumn')
        };
        const postalCodeCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.postalCode'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).supplyAddress.postalCode
            }
        };
        const meterPointCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.meterPoint'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).meterPoint
            }
        };
        const tariffCol = {
            dataField: 'tariff',
            text: i18n.t('agreement.tariffName'),
            sort: true
        };
        const productsCol = {
            dataField: 'products',
            text: i18n.t('agreement.products'),
            formatter: (cell, row) => row.products.map(e => e.name.includes("Contract") ? e.name : `Contract for ${e.name}`).join('\n')
        };
        const contractDurationCol = {
            dataField: 'extraData',
            text: i18n.t('agreement.contractDuration'),
            // sort: true,
            formatter: (cell, row) => {
                return JSON.parse(cell).contractDuration
            }
        };
        const aqCol = {
            dataField: 'extraData',
            // text: (window.config.consul.ELECTRICITY_SERVICE || window.ELECTRICITY_SERVICE || (typeof ELECTRICITY_SERVICE !== 'undefined' && ELECTRICITY_SERVICE))  и ,
            text: ('Annual usage'),
            // sort: true,
            formatter: (cell, row, i) => {
                return !JSON.parse(cell).aq ? JSON.parse(cell).products.map(e => e.annualQuantity).join('\n') : JSON.parse(cell).aq;
            }
        };

        const statusCol = {
            dataField: 'status.name',
            text: i18n.t('agreement.status'),
            sort: true,
            formatter: cell => i18n.t(cell)
        };
        const startDateCol = {
            dataField: 'startDate',
            text: i18n.t('agreement.startDate'),
            sort: true,
            // editable: (content, row, rowIndex, columnIndex) => content > 2101,
            formatter: (cell, row) => {
                return cell ? new Date(cell).toLocaleDateString() : null;
            }
        };
        const endDateCol = {
            dataField: 'endDate',
            text: i18n.t('agreement.endDate'),
            sort: true,
            formatter: (cell, row) => {
                return cell ? new Date(cell).toLocaleDateString() : null;
            }
        };
        const documentNumber = {
            dataField: 'documentNumber',
            text: 'Contract No',
            sort: true,
        }

        const columns = (window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? [
            dateCol,
            clientNameCol,
            ownerOrganizationNameCol,
            subOwnerOrganizationCol,
            meterPointCol,
            productsCol,
            contractDurationCol,
            aqCol,
            statusCol,
            startDateCol,
            documentNumber
        ] : [
            agreementNumberCol,
            dateCol,
            clientNameCol,
            ownerOrganizationNameCol,
            subOwnerOrganizationCol,
            postalCodeCol,
            meterPointCol,
            tariffCol,
            productsCol,
            statusCol,
            startDateCol,
            endDateCol
        ];

        return (
            <ApolloProvider client={agreementsClient}>
                <ApolloConsumer>
                    {client => {
                        return <div><Query query={getQuoteProductNamesQuery}>
                            {(resultProductNames) => <Mutation mutation={setAgreementStatusMutation}>
                                {(setAgreementStatus) => (<div><Query query={getAgreementStatusesQuery}>
                                        {(result) => {
                                            return (
                                                <PaginatedQueryDataGrid dataPath={'getAgreements.content'}
                                                                        paginationPath={'getAgreements'} 
                                                                        keyField={'id'}
                                                                        serverSidePaging={true}
                                                                        query={agreementsQuery}
                                                                        variables={{
                                                                            organizationId: this.props.organizationId,
                                                                            username: this.props.username
                                                                        }}
                                                                        saveFilter
                                                                        columns={columns}
                                                                        menuItems={async (obj) => {
                                                                            const {data} = await client.query({
                                                                                query: getAgreementStatusFlowFromQuery,
                                                                                variables: {
                                                                                    agreement: obj.id
                                                                                }
                                                                            });
                                                                            return [
                                                                                ...(obj.status.key !== "DRAFT" &&
                                                                                    obj.obId ?
                                                                                        [{
                                                                                            name: i18n.t('Renew'),
                                                                                            skipRefetch: true,
                                                                                            callback: (obj) => {
                                                                                                this.setState({
                                                                                                    agreementId: obj.id,
                                                                                                    sourceQuoteId: obj.extraData && (JSON.parse(obj.extraData)).sourceQuoteId || obj.sourceQuoteId,
                                                                                                    obId: obj.obId
                                                                                                });
                                                                                                this.toggleStartRenewal();
                                                                                                return Promise.resolve('done');
                                                                                            }
                                                                                        }]
                                                                                        : []
                                                                                ),
                                                                                ...data.getAgreementStatusFlowFrom.map(({to}) => ({
                                                                                    name: i18n.t(to.action),
                                                                                    callback: () => {
                                                                                        if (to.key === "SIGNED") {
                                                                                            this.toggleUploadModal(to, setAgreementStatus, obj.id, obj.processInstanceId);
                                                                                            const promise = new Promise((resolve, reject) => {
                                                                                                this.setState({statusPromiseResolve: resolve});
                                                                                            });

                                                                                            return promise;
                                                                                        } else if (!to.requireReason) {
                                                                                            this.updateStatus(to, obj.id);
                                                                                            return new Promise((resolve, reject) => {
                                                                                                this.setState({statusPromiseResolve: resolve});
                                                                                            });
                                                                                        } else {
                                                                                            this.toggleReasonModal(to, setAgreementStatus, obj.id);
                                                                                            return new Promise((resolve, reject) => {
                                                                                                this.setState({statusPromiseResolve: resolve});
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })), {
                                                                                    name: i18n.t('agreementStatus.detailsAction'),
                                                                                    href: (obj) => {
                                                                                        if (obj.obId) {
                                                                                            return '/#/agreements-old/' + obj.obId;
                                                                                        } else {
                                                                                            return '/#/agreements/' + obj.id;
                                                                                        }
                                                                                    }
                                                                                },
                                                                                ...(obj.documentNumber === null || obj.status.key === "RENEWAL" ? [{
                                                                                    name: i18n.t('agreementStatus.printAction'),
                                                                                    callback: (obj) => {
                                                                                        return axios.get(`${(window.config.consul.SALESPORTAL_URL || window.SALESPORTAL_URL || (typeof SALESPORTAL_URL !== 'undefined' ? SALESPORTAL_URL : ''))}/contractPrint/${obj.id}`, {
                                                                                            headers: {'Authorization': "Bearer " + localStorage.token},
                                                                                            responseType: 'blob'
                                                                                        }).then((response) => {
                                                                                            fileDownload(response.data, 'Contract ' + obj.clientName + '.pdf');
                                                                                        })
                                                                                    }
                                                                                }] : [])
                                                                            ];
                                                                        }}
                                                                        noDataView={<NoAgreements/>}
                                                                        defaultSorted={{
                                                                            dataField: 'date',
                                                                            order: 'desc'
                                                                        }}
                                                                        filters={[
                                                                            {
                                                                                type: 'text',
                                                                                fields: ['extraData.supplyAddress', 'extraData.documentNumber', 'clientName', 'ownerOrganizationName', 'subOwnerOrganizationName', 'extraData', 'extraData.products'],
                                                                                placeholder: i18n.t("agreement.searchPrompt"),
                                                                                classes: "search",
                                                                                cols: 2
                                                                            },
                                                                            {
                                                                                type: 'dropdown',
                                                                                fields: ['extraData.products.0.name'],
                                                                                placeholder: "All Products",
                                                                                possibleValues: resultProductNames.loading ? [] : resultProductNames.data.getQuoteProductNames.map((name) => ({
                                                                                    key: name,
                                                                                    value: name
                                                                                })),
                                                                                classes: "all-products",
                                                                                cols: 2
                                                                            },
                                                                            {
                                                                                type: 'dropdown',
                                                                                isExactMatch: true,
                                                                                fields: ['status.key'],
                                                                                possibleValues: result.loading ? [] : result.data.getAgreementStatuses.map(({
                                                                                                                                                                key,
                                                                                                                                                                name
                                                                                                                                                            }) => ({
                                                                                    key,
                                                                                    value: i18n.t(name)
                                                                                })),
                                                                                placeholder: "All Statuses",
                                                                                classes: "all-statuses",
                                                                                cols: 2
                                                                            },
                                                                            {
                                                                                type: 'date',
                                                                                label: 'Dates',
                                                                                fields: ['startDate'],
                                                                                classes: "agreement-start",
                                                                                cols: 3
                                                                            }
                                                                        ]}
                                                />)
                                        }}
                                    </Query>
                                        <Modal isOpen={this.state.changeStartDateOpen} toggle={this.toggleChangeStartDate}>
                                            <ModalHeader toggle={this.toggleChangeStartDate}>
                                                Change Start Date
                                            </ModalHeader>
                                            <ModalBody>
                                                <p>First possible start date: <span>{moment().add(15, 'd').format('LL')}</span>
                                                </p>
                                                <label htmlFor="start-date">Select new start date:</label>
                                                <br/>
                                                <input type="date"
                                                       id="start-date"
                                                       onChange={event => {
                                                           this.setState({
                                                               agreementStartDate: event.target.value + "T00:00:00Z"
                                                           })
                                                       }}
                                                       value={moment(this.state.agreementStartDate).format('YYYY-MM-DD')}
                                                       min={moment().add(15, 'd').format('YYYY-MM-DD')}
                                                />
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="primary" id="submit-button" onClick={async () => {
                                                    if (this.state.agreementStartDate >= moment().add(15, 'd').format('YYYY-MM-DD')) {
                                                        await client.mutate({
                                                            mutation: updateAgreementStartDateQuery,
                                                            variables: {
                                                                agreementId: this.state.agreementId,
                                                                date: this.state.agreementStartDate
                                                            },
                                                            refetchQueries: [
                                                                {query: agreementsQuery},
                                                                {query: getAgreementStatusesQuery},
                                                                {
                                                                    query: getAgreementStatusFlowFromQuery,
                                                                    variables: {agreement: this.state.agreementId}
                                                                },
                                                            ],
                                                        });
                                                        this.setState({
                                                            agreementStartDate: moment().add(15, 'd').format('YYYY-MM-DD'),
                                                            popoverOpen: false
                                                        })
                                                        this.toggleChangeStartDate();
                                                    } else {
                                                        this.setState({
                                                            popoverOpen: true
                                                        })
                                                    }
                                                }}>Submit</Button>{' '}
                                                <Popover placement="top" isOpen={this.state.popoverOpen} target="submit-button"
                                                         toggle={this.state.popoverOpen}>
                                                    <PopoverBody>Plaease insert a valid date</PopoverBody>
                                                </Popover>
                                                <Button color="secondary" onClick={() => {
                                                    this.setState({
                                                        agreementStartDate: moment().add(15, 'd').format('YYYY-MM-DD'),
                                                        popoverOpen: false
                                                    });
                                                    this.toggleChangeStartDate();
                                                }}>Cancel</Button>
                                            </ModalFooter>
                                        </Modal>

                                        <Modal
                                            isOpen={this.state.startRenewal}
                                            toggle={this.toggleStartRenewal}
                                            style={{minWidth: "600px"}}
                                            backdrop={false}
                                            className={"renew-modal"}
                                        >
                                            <ModalHeader toggle={this.toggleStartRenewal}>
                                                Start Renewal Process
                                            </ModalHeader>
                                            {!this.state.continueRenewal ? (
                                                <ModalBody>
                                                    <FormGroup>
                                                        <Label for="startDate">Start Date *</Label>
                                                        <SingleDatePicker
                                                            date={this.state.startDate} // momentPropTypes.momentObj or null
                                                            displayFormat="DD/MM/YY"
                                                            onDateChange={(date) =>
                                                                this.setState({
                                                                    startDate: date,
                                                                    month: date.format("MMM-YY")
                                                                })
                                                            }// PropTypes.func.isRequired
                                                            focused={this.state.focused} // PropTypes.bool
                                                            onFocusChange={({focused}) => this.setState({focused})}
                                                            id="startDate" // PropTypes.string.isRequired,
                                                            // Allow users to select a date from tomorrow
                                                            isOutsideRange={(date) => date.isSameOrBefore(moment(), 'day')}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="aq">AQ *</Label>
                                                        <Input type="number" name="aq" id="aq"
                                                               value={this.state.aq}
                                                               onChange={(e) => {
                                                                   this.setState({aq: e.target.value})
                                                               }}
                                                               min={1000}
                                                               max={399999}
                                                               placeholder="Annual quantity"
                                                               required/>
                                                    </FormGroup>
                                                </ModalBody>
                                            ) : (
                                                <ModalBody>
                                                    <div style={{padding: "20px"}}>
                                                        <Query query={contractQuery}
                                                               variables={{where: `id= '${this.state.obId}'`}}>
                                                            {({loading, error, data}) => {
                                                                if (loading) return <Loading />;
                                                                if (error) return `Error! ${error.message}`;

                                                                data.getOBContract = JSON.parse(data.getOBContract);
                                                                data.getOBContract.saleType = "Renewal";
                                                                return (<Row className='step'>
                                                                    <Col className='col-12 step-header'>
                                                                        <h2>Select tariff details</h2>
                                                                    </Col>
                                                                    <Col className='col-12 step-body'>
                                                                        <Row>
                                                                            <Col className='col-12'>
                                                                                <ApolloProvider client={agreementsClient}>
                                                                                    <SelectTariffs setTariff={(tariff) => {
                                                                                        const getTariffSc = (tariff, scKey) => {
                                                                                            const getBaseStandingCharge = (params) => {
                                                                                              for (const key in params) {
                                                                                                if (params.hasOwnProperty(key) && key.toLowerCase().includes(BASE_PRICE_STRINGS.BASE_SC.toLowerCase())) {
                                                                                                  return params[key];
                                                                                                }
                                                                                              }
                                                                                              return null; // Return null if no matching key is found
                                                                                            };
                                                                                      
                                                                                            return {
                                                                                              sc: tariff.sc || (tariff.productPrices && tariff.productPrices[scKey] && tariff.productPrices[scKey].price),
                                                                                              baseSc: tariff.baseSc || (tariff.parameters && getBaseStandingCharge(tariff.parameters)),
                                                                                            };
                                                                                        };

                                                                                        let tariffData = tariff;
                                                                                        let services = data.getOBContract.products[0].name === 'Contract for Gas' ? 'gas' : 'electricity'

                                                                                        if (tariff.productPrices) {
                                                                                            tariffData = tariff.productPrices.reduce((acc, curr, i) => {
                                                                                                const product = curr.searchKey
                                                                                                    .split(" ")
                                                                                                    .map((e, index) =>
                                                                                                        index > 0 ? e.charAt(0).toUpperCase() + e.slice(1) : e
                                                                                                    )
                                                                                                    .join("");
                                                                                                return { ...acc, [product]: i };
                                                                                            }, {});
                                                                                        }

                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            agreementData: data.getOBContract,
                                                                                            contractDuration:
                                                                                                    tariff.parameters && tariff.parameters.Term
                                                                                                        ? tariff.parameters.Term.split(" ")[0]
                                                                                                        : this.state.contractDuration,
                                                                                            quote: {
                                                                                                ...this.state.quote,
                                                                                                //save the service in state to later use in handleTariffNameChangeBySubmit
                                                                                                service: services,
                                                                                                tariffName: {
                                                                                                    ...tariff,
                                                                                                    id: "1",
                                                                                                    ...getTariffSc(
                                                                                                        tariff,
                                                                                                        services === 'gas' ? tariffData.standingCharge : tariffData.standingChargeElectricity
                                                                                                    ),
                                                                                                    ...(services === "gas"
                                                                                                        ? {
                                                                                                                unitRate:
                                                                                                                    tariff.unitRate !== undefined
                                                                                                                        ? tariff.unitRate
                                                                                                                        : tariff.productPrices &&
                                                                                                                        tariff.productPrices[tariffData.gasConsumption].price,
                                                                                                                baseUnitRate:
                                                                                                                    tariff.baseUnitRate !== undefined
                                                                                                                        ? tariff.baseUnitRate
                                                                                                                        : tariff.parameters &&
                                                                                                                        tariff.parameters[BASE_PRICE_STRINGS.BASE_UNIT_RATE],
                                                                                                        }
                                                                                                        : {
                                                                                                                ...(tariffData.hasOwnProperty("dayRate") &&
                                                                                                                    tariffData.hasOwnProperty("nightRate") &&
                                                                                                                    tariffData.hasOwnProperty("evening/weekendRate") &&
                                                                                                                    !tariffData.hasOwnProperty("singleRate") && {
                                                                                                                        dayRate: tariff.dayRate
                                                                                                                            ? tariff.dayRate
                                                                                                                            : tariff.productPrices[tariffData.dayRate].price,
                                                                                                                        baseDayRate: tariff.baseDayRate || 
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_DAY_RATE]),
                                                                                                                        nightRate: tariff.nightRate
                                                                                                                            ? tariff.nightRate
                                                                                                                            : tariff.productPrices[tariffData.nightRate].price,
                                                                                                                        baseNightRate: tariff.baseNightRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_NIGHT_RATE]),
                                                                                                                        eveningWeekendRate:
                                                                                                                            tariff.eveningWeekendRate !== undefined
                                                                                                                                ? tariff.eveningWeekendRate
                                                                                                                                : tariff.productPrices[
                                                                                                                                    tariffData["evening/weekendRate"]
                                                                                                                                ].price,
                                                                                                                        baseEveningWeekendRate: tariff.baseEveningWeekendRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_EVENING_WEEKEND_RATE]),
                                                                                                                    }),
                                                                                                                ...(tariffData.hasOwnProperty("dayRate") &&
                                                                                                                    tariffData.hasOwnProperty("nightRate") &&
                                                                                                                    !tariffData.hasOwnProperty("evening/weekendRate") &&
                                                                                                                    !tariffData.hasOwnProperty("singleRate") && {
                                                                                                                        dayRate: tariff.dayRate
                                                                                                                            ? tariff.dayRate
                                                                                                                            : tariff.productPrices[tariffData.dayRate].price,
                                                                                                                        baseDayRate: tariff.baseDayRate || 
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_DAY_RATE]),
                                                                                                                        nightRate: tariff.nightRate
                                                                                                                            ? tariff.nightRate
                                                                                                                            : tariff.productPrices[tariffData.nightRate].price,
                                                                                                                        baseNightRate: tariff.baseNightRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_NIGHT_RATE]),
                                                                                                                    }),
                                                                                                                ...(!tariffData.hasOwnProperty("dayRate") &&
                                                                                                                    !tariffData.hasOwnProperty("nightRate") &&
                                                                                                                    !tariffData.hasOwnProperty("evening/weekendRate") &&
                                                                                                                    tariffData.hasOwnProperty("singleRate") && {
                                                                                                                        singleRate: tariff.singleRate
                                                                                                                            ? tariff.singleRate
                                                                                                                            : tariff.productPrices[tariffData.singleRate].price,
                                                                                                                        baseSingleRate: tariff.baseSingleRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_SINGLE_RATE]),
                                                                                                                    }),
                                                                                                                ...(!tariffData.hasOwnProperty("dayRate") &&
                                                                                                                    !tariffData.hasOwnProperty("nightRate") &&
                                                                                                                    tariffData.hasOwnProperty("evening/weekendRate") &&
                                                                                                                    !tariffData.hasOwnProperty("singleRate") && {
                                                                                                                        eveningWeekendRate:
                                                                                                                            tariff.eveningWeekendRate !== undefined
                                                                                                                                ? tariff.eveningWeekendRate
                                                                                                                                : tariff.productPrices[
                                                                                                                                    tariffData["evening/weekendRate"]
                                                                                                                                ].price,
                                                                                                                        baseEveningWeekendRate: tariff.baseEveningWeekendRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_EVENING_WEEKEND_RATE]),
                                                                                                                    }),
                                                                                                                ...(tariffData.hasOwnProperty("dayRate") &&
                                                                                                                    !tariffData.hasOwnProperty("nightRate") &&
                                                                                                                    tariffData.hasOwnProperty("evening/weekendRate") &&
                                                                                                                    !tariffData.hasOwnProperty("singleRate") && {
                                                                                                                        dayRate: tariff.dayRate
                                                                                                                            ? tariff.dayRate
                                                                                                                            : tariff.productPrices[tariffData.dayRate].price,
                                                                                                                        baseDayRate: tariff.baseDayRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_DAY_RATE]),
                                                                                                                        eveningWeekendRate:
                                                                                                                            tariff.eveningWeekendRate !== undefined
                                                                                                                                ? tariff.eveningWeekendRate
                                                                                                                                : tariff.productPrices[
                                                                                                                                    tariffData["evening/weekendRate"]
                                                                                                                                ].price,
                                                                                                                        baseEveningWeekendRate: tariff.baseEveningWeekendRate ||
                                                                                                                            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_EVENING_WEEKEND_RATE]),
                                                                                                                    }),
                                                                                                        }),
                                                                                                        brokerId: "DYCE",
                                                                                                        tariffCode: tariff.name,
                                                                                                        ...(tariff.parameters ? {
                                                                                                            aqLow: tariff.parameters.AQmin,
                                                                                                            aqHigh: tariff.parameters.AQmax,
                                                                                                            billingFrequency: tariff.parameters["Billing Frequency"],
                                                                                                            earliestStartDate: tariff.parameters["Earliest Start date"],
                                                                                                            latestStartDate: tariff.parameters["Latest Start Date"],
                                                                                                            ldz: tariff.parameters.LDZ,
                                                                                                            term: tariff.parameters.Term
                                                                                                        } : {
                                                                                                            aqLow: null,
                                                                                                            aqHigh: null,
                                                                                                            billingFrequency: null,
                                                                                                            earliestStartDate: null,
                                                                                                            latestStartDate: null,
                                                                                                            ldz: null,
                                                                                                            term: null,
                                                                                                        }),
                                                                                                    },
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                                   value={this.state.quote && this.state.quote.tariffName||""}
                                                                                                   quote={Object.assign(data.getOBContract, {
                                                                                                       month: this.state.month,
                                                                                                       startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                                                                                                       services: data.getOBContract.products[0].name === 'Contract for Gas' ? 'gas' : 'electricity',
                                                                                                   })}
                                                                                                   firstStepData={this.state}
                                                                                                   userRoles={this.props.currentUser.roles}
                                                                                                   useBespokePrice={true}
                                                                                    />
                                                                                </ApolloProvider>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col className='col-6'>
                                                                                {this.props.currentUser.roles.some(role => role.role.key === 'SALESADMIN') && <FormGroup>
                                                                                    <Label for="contractDuration">Select Contract Duration in months *</Label>
                                                                                    <Input
                                                                                        type="number"
                                                                                        min={1}
                                                                                        step={1}
                                                                                        name="contractDuration"
                                                                                        id="contractDuration"
                                                                                        placeholder="Select contract duration"
                                                                                        value={this.state.contractDuration }
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                ...this.state,
                                                                                                contractDuration: e.target.value
                                                                                            });
                                                                                        }}
                                                                                        required
                                                                                    />
                                                                                </FormGroup>}
                                                                                <FormGroup>
                                                                                    <Label for="commissionUnitRate">Commission
                                                                                        Amount for Unit Rate (p/kWh) *</Label>
                                                                                    <Input type="number"
                                                                                           step={(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) ? 0.01 : 0.001}
                                                                                           min="0"
                                                                                           max={(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) && !this.global.permissions.includes("Quotes.UnlimitedCommissionUnitRate") ? 3 : Number.MAX_SAFE_INTEGER}
                                                                                           name="commissionUnitRate"
                                                                                           id="commissionUnitRate"
                                                                                           value={this.state.commissionUnitRate}
                                                                                           onChange={(e) => {
                                                                                               this.setState({commissionUnitRate: e.target.value})
                                                                                           }}
                                                                                           placeholder="Commission Amount for Unit Rate"
                                                                                           required/>
                                                                                </FormGroup>

                                                                                <FormGroup>
                                                                                    <Label for="commissionStandingCharge">Commission
                                                                                        Amount for Standing Charge
                                                                                        (p/day) *</Label>
                                                                                    <Input type="number"
                                                                                           step={(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) ? 1 : 0.001}
                                                                                           min="0"
                                                                                           max={(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) && !this.global.permissions.includes("Quotes.UnlimitedCommissionStandingCharge") ? 100 : Number.MAX_SAFE_INTEGER}
                                                                                           name="commissionStandingCharge"
                                                                                           id="commissionStandingCharge"
                                                                                           value={this.state.commissionStandingCharge}
                                                                                           onChange={(e) => {
                                                                                               this.setState({commissionStandingCharge: e.target.value})
                                                                                           }}
                                                                                           placeholder="Commission Amount for Standing Charge"
                                                                                           required/>
                                                                                </FormGroup>

                                                                                <FormGroup>
                                                                                    <Label for="tariffId">Tariff Matrix Identifier *</Label>
                                                                                    <Input type="text"
                                                                                           name="Tariff Matrix Identifier"
                                                                                           id="Tariff Matrix Identifier"
                                                                                           disabled={!this.props.currentUser.roles.some(role => role.role.key === 'SALESADMIN')}
                                                                                           value={(this.state.quote && this.state.quote.tariffName.parameters) && this.state.quote.tariffName.parameters['Tariff Matrix Identifier']}
                                                                                           onChange={(e) => {
                                                                                               if (this.state.quote) {
                                                                                                   if (!this.state.quote.tariffName.parameters) {
                                                                                                       this.state.quote.tariffName.parameters = {['Tariff Matrix Identifier']: e.target.value};
                                                                                                   } else {
                                                                                                       this.state.quote.tariffName.parameters['Tariff Matrix Identifier'] = e.target.value;
                                                                                                   }
                                                                                                   this.setState({quote: {...this.state.quote}})
                                                                                               } else if (!this.state.quote) {
                                                                                                   this.setState({
                                                                                                       ...this.state,
                                                                                                       quote: {
                                                                                                           tariffName: {
                                                                                                               parameters: {
                                                                                                                   ['Tariff Matrix Identifier']: e.target.value
                                                                                                               }
                                                                                                           }
                                                                                                       }
                                                                                                   })
                                                                                               }
                                                                                           }}
                                                                                           placeholder="Tariff Matrix Identifier"
                                                                                           required/>
                                                                                </FormGroup>

                                                                                {this.global.permissions.includes("Quotes.CreateQuoteOnBehalfOfAgent") &&
                                                                                    <ApolloProvider client={agreementsClient}>
                                                                                        <SalesAgentSelect
                                                                                            client={client}
                                                                                            isDisabledInput={!this.state.agreementData}
                                                                                            ownerOrgInputOnChange={this.setBroker}
                                                                                            salesAgentInputOnChange={this.setAdduserList}
                                                                                        />
                                                                                    </ApolloProvider>
                                                                                }

                                                                                {(this.props.currentUser.roles.some(role => role.role.key === 'SALESADMIN') && window.config.consul.UPFRONT_PAYMENTS_MSG_ENABLED) && 
                                                                                <Fragment>
                                                                                    {/*checkbox for advanced price, only visible for admin roles */}
                                                                                    <Input type="checkbox" name='advancedPrice' id='advancedPrice'
                                                                                        className="css-checkbox"
                                                                                        checked={this.state.advancedPrice}
                                                                                        onChange={e => {
                                                                                            this.setState({
                                                                                                ...this.state,
                                                                                                advancedPrice: e.target.checked});
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor='advancedPrice' className="css-label"><i className="icon ion-android-checkbox"></i>
                                                                                        This is an advanced saver tariff - a payment will be taken on or around go live date</label>
                                                                                </Fragment>}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>)
                                                            }}</Query></div>
                                                </ModalBody>
                                            )}
                                            <ModalFooter>
                                                {!this.state.continueRenewal ? (
                                                        <Button color="primary" disabled={!(this.state.startDate && this.state.month && this.state.aq)} onClick={() => {
                                                            if (this.state.aq < 1000) {
                                                                return ReactDOM.render(<ExpiringAlert color="danger"
                                                                                                      message={'Minimum AQ value: 1000'}/>, document.getElementById('alert'));
                                                            }
                                                            this.setState({continueRenewal: true})
                                                        }}>Continue</Button>
                                                    ) : (
                                                        <Fragment>
                                                            <Button color="primary" id="submit-button" onClick={() => {
                                                                if (!this.state.quote && !this.state.quote.tariffName) {
                                                                    return ReactDOM.render(<ExpiringAlert color="danger"
                                                                                                          message={'Please select a tariff'}/>, document.getElementById('alert'));
                                                                }
                                                                if (!this.state.commissionUnitRate ||
                                                                    !this.state.commissionStandingCharge ||
                                                                    !this.state.contractDuration ||
                                                                    !this.state.quote.tariffName.parameters["Tariff Matrix Identifier"] ||
                                                                    !this.state.startDate ||
                                                                    (isAdmin && !this.state.isBrokerAndSalesAgentFilledIn)
                                                                ) {
                                                                    return ReactDOM.render(<ExpiringAlert color="danger"
                                                                                                          message={'Please fill required fields'}/>, document.getElementById('alert'));
                                                                } 

                                                                if (!parseInt(this.state.contractDuration) || this.state.contractDuration < 1) {
                                                                    return ReactDOM.render(<ExpiringAlert color="danger"
                                                                        message={'Please enter a valid contract duration! Value must be at least 1'} />, document.getElementById('alert'));
                                                                }
                                                                this.handleTariffNameChangeBySubmit();
                                                            }}>Submit</Button>{' '}
                                                                <Button color="secondary" onClick={() => {
                                                                this.toggleStartRenewal();
                                                            }}>Cancel</Button>
                                                        </Fragment>
                                                    )
                                                }
                                            </ModalFooter>
                                        </Modal>

                                        <Modal isOpen={this.state.reasonModalOpen} toggle={this.toggleReasonModal}
                                               className="quote-preview-model">
                                            <ModalHeader toggle={this.toggleReasonModal}>
                                                Enter reason:
                                            </ModalHeader>
                                            <ModalBody>
                                                <Form>
                                                    <FormGroup>
                                                        <Input type={"select"} value={this.state.statusReason}
                                                               onChange={(e) => this.setState({statusReason: e.target.value})}>
                                                            <option/>
                                                            {window.config.consul.AGREEMENT_REJECTION_REASONS.split(",").map(reason => <option
                                                                value={reason}>{reason}</option>)}
                                                        </Input>
                                                    </FormGroup>
                                                </Form>

                                                <Button color='primary' onClick={() => {
                                                    const {__typename, ...statusToSet} = this.state.statusToSet;

                                                    this.updateStatus(statusToSet, this.state.agreementId);

                                                    this.toggleReasonModal(null, null, null);
                                                    this.setState({statusReason: null});
                                                }}>Submit</Button>

                                                <Button color='secondary' onClick={() => {
                                                    this.toggleReasonModal(null, null, null);
                                                    this.setState({statusReason: null});
                                                }}>Cancel</Button>
                                            </ModalBody>
                                        </Modal>
                                        <Modal isOpen={this.state.uploadModalOpen} toggle={this.toggleUploadModal}
                                               className="quote-preview-model">
                                            <ModalHeader toggle={this.toggleUploadModal}>
                                                Attach Signed Contract:
                                            </ModalHeader>
                                            <ModalBody>
                                                <Form>
                                                    <FormGroup>
                                                        <Input type="file" style={{marginBottom: '0.5em'}}
                                                               onChange={(e) => {
                                                                   this.setState({
                                                                       ...this.state,
                                                                       file: [...this.state.file, e.target.files[0]]
                                                                   })
                                                               }}>
                                                        </Input>
                                                        {this.state.file.map((file, i) => {
                                                            return (
                                                                <FormText style={{marginBottom: '0.2em'}}>
                                                                    {file.name}
                                                                    <i style={{marginLeft: '0.2em'}} className="fa fa-times" aria-hidden="true"
                                                                       onClick={() => {this.removeAttachment(file.name)}}/>
                                                                </FormText>
                                                            )
                                                        })}
                                                    </FormGroup>
                                                </Form>

                                                <Button color='primary' onClick={() => {
                                                    if (this.state.file && this.state.file.length > 0) {
                                                        const data = new FormData();
                                                        this.state.file.forEach((file, i) => {
                                                            data.append('file', file);
                                                        });
                                                        axios.post((window.config.consul.SALESPORTAL_URL || window.SALESPORTAL_URL || (typeof SALESPORTAL_URL !== 'undefined' ? SALESPORTAL_URL : '')) + "/attachment/" + this.state.processInstanceId + "/CONTRACT",
                                                            data,
                                                            {headers: {'Authorization': "Bearer " + localStorage.token}}).then(
                                                            () => {
                                                                const {__typename, ...statusToSet} = this.state.statusToSet;
                                                                this.updateStatus(statusToSet, this.state.agreementId);
                                                                this.toggleUploadModal(null, null, null);
                                                                this.setState({statusReason: null});
                                                            }
                                                        ).catch((error) => {
                                                            ReactDOM.render(<ExpiringAlert color="danger"
                                                                                           message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                                            console.log('There was an error uploading the file', error.message);
                                                        });
                                                    } else {
                                                        const {__typename, ...statusToSet} = this.state.statusToSet;
                                                        this.updateStatus(statusToSet, this.state.agreementId);
                                                        this.toggleUploadModal();
                                                        this.setState({statusReason: null});
                                                    }
                                                }}>Mark Contract As Signed</Button>

                                                <Button color='secondary' onClick={() => {
                                                    this.toggleUploadModal();
                                                }}>Cancel</Button>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                )}
                            </Mutation>}
                        </Query></div>}}
                </ApolloConsumer>
            </ApolloProvider>
        )
    }
}

export default withRouter(AgreementsGrid);
