import React, { Component } from 'reactn';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from '../../utils/Client';
import { v4 as uuidv4 } from 'uuid';
import { withApollo } from 'react-apollo';
import { OB_regimeConfiguration } from '../../queries/Queries';
import Loading from '../Loading/Loading';

const tariffRateDetails = {
  'Unit Rate': {title: 'Unit Rate', inputProp: 'unitRate', rateUnit: 'p/kWh', minStep: 0.001, required: true},
  'Electricity Single Rate': {title: 'Single Rate', inputProp: 'singleRate', rateUnit: 'p/kWh', minStep: 0.001, required: true},
  'Electricity Two Rate Day': {title: 'Day Rate', inputProp: 'dayRate', rateUnit: 'p/kWh', minStep: 0.001, required: true},
  'Electricity Two Rate Night': {title: 'Night Rate', inputProp: 'nightRate', rateUnit: 'p/kWh', minStep: 0.001, required: true},
  'Electricity Evening/Weekend': {title: 'Evening/Weekend Rate', inputProp: 'eveningWeekendRate', rateUnit: 'p/kWh', minStep: 0.001, required: true},
  'kVa Charge': {title: 'kVa Charge', inputProp: 'kVaCharge', rateUnit: 'p/kVa/day', minStep: 0, required: true},
  'Metering Charge': {title: 'Metering Charge', inputProp: 'meteringCharge', rateUnit: 'p/day', minStep: 0, required: true},
  'Standing charges Electricity': {title: 'Standing Charge', inputProp: 'sc', rateUnit: 'p/day', minStep: 0, required: true},
  'Standing charges Gas': {title: 'Standing Charge', inputProp: 'sc', rateUnit: 'p/day', minStep: 0, required: true},
}

class SelectTariffs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tariffs: [],
      inputFieldsByRegimeConf: [],
      isLoading: true,
    };
    this.displayTariffs = this.displayTariffs.bind(this);
    this.filterTariffsByRegimeConfig = this.filterTariffsByRegimeConfig.bind(this);
    this.structureRespByIndex = this.structureRespByIndex.bind(this);
    this.renderTariffInputs = this.renderTariffInputs.bind(this);
    this.renderInputField = this.renderInputField.bind(this);
    this.tariffFieldOnChange = this.tariffFieldOnChange.bind(this);
  }

  renderInputField(inputPropName, title, placeHolder, value, required, minStep, disabled) {
    return (
      <FormGroup>
        <Label for={inputPropName}>{title}</Label>
        <Input
          type='number'
          name={inputPropName}
          id={inputPropName}
          {...(inputPropName === 'dayRate' && 
            { 'data-tariff-field': inputPropName })
          }
          step='0.001'
          min={minStep}
          onChange={(e) => this.tariffFieldOnChange(inputPropName, e)}
          value={value}
          placeholder={placeHolder}
          required={required}
          disabled={disabled}
        />
      </FormGroup>
    );
  }

  /**
   * 
   * @param {Array} inputFields Array of input fields
   * @param {Object} quote  Quote object
   * @param {props} propsValues this.props object
   * @param {String} prefix  Prefix for Input properties name and title
   * @param {boolean} disabled To disable the input field
   * @returns JSX Element
   */
  renderTariffInputs(inputFields, quote, propsValues, prefix = '', disabled = false) {
    // Check quote for the Metering Charge and HH Meter
    const hasSignedAgentContract = quote.hasSignedAgentContract;
    const isHHMeter = quote.isHHMeter;
    const isGas = quote.services === 'gas';

    let fieldsToRender = [...inputFields];
    let title, inputProp, rateUnit, minStep, required;
    let finalTitle = '';
    let placeHolder = '';
    let value = '';

    // regimeConfigurations query returns the input fields only for electricity
    // so we need to check if the services is gas and add the gas fields
    if (isGas) {
      fieldsToRender = ['Unit Rate', 'Standing charges Gas']
    } else if (quote.services === 'electricity') {
      // fallback to default fields if the fieldsToRender is empty
      if(fieldsToRender.length === 0) {
        fieldsToRender = ['Electricity Single Rate', 'Electricity Two Rate Day', 'Electricity Two Rate Night', 'Electricity Evening/Weekend'];
      }
      // Push the Standing charges Electricity to the fieldsToRender
      fieldsToRender.push('Standing charges Electricity');
    }

    // Push the kVa Charge and Metering Charge to the fieldsToRender array
    if (isHHMeter) {
      fieldsToRender.push('kVa Charge');
      if (!hasSignedAgentContract) fieldsToRender.push('Metering Charge');
    }

    // Map the fieldsToRender to the input fields
    return fieldsToRender.map((field) => {
      const tariffDetail = tariffRateDetails[field];
      if (!tariffDetail) return null;
  
      ({ title, inputProp, rateUnit, minStep, required } = tariffDetail);
      placeHolder = title;
      const requiredSymbol = required ? '*' : '';
  
      // Capitalize the first letter of inputProp 
      if (prefix) {
        inputProp = `base${inputProp.charAt(0).toUpperCase()}${inputProp.slice(1)}`;
      }
      finalTitle = `${prefix}${title} ${rateUnit} ${requiredSymbol}`;
      placeHolder = `${prefix}${placeHolder}`;
      value = propsValues.value[inputProp] !== undefined ? propsValues.value[inputProp] : '';

      return (
        this.renderInputField(inputProp, finalTitle, placeHolder, value, required, minStep, disabled)
      );
    });
  }

  filterTariffsByRegimeConfig(data, regimeConfig) {
    return data.reduce((acc, curr) => {
      /*display only tariffs that have
        1. the same number of products (exclude Standing charge because it is offered in all tariffs)
        2. the same type of products as the regimeConfig*/
      const isValidTariff =
        curr.productPrices.length - 1 !== regimeConfig.length
          ? false
          : !curr.productPrices.some((rate) => {
              const firstWord = rate.name.split(' ')[0].toLowerCase();
              return (
                rate.name !== 'Standing charge electricity' &&
                !regimeConfig.join(', ').toLowerCase().includes(firstWord)
              );
            });

      return isValidTariff ? acc.concat(curr) : acc;
    }, []);
  }

  structureRespByIndex(data) {
    return data.reduce((acc, curr) => {
      let value = {};
      curr.productPrices.forEach((key, i) => {
        const product = key.searchKey
          .split(' ')
          .map((e, i) => (i > 0 ? e.charAt(0).toUpperCase() + e.slice(1) : e))
          .join('');
        value = { ...value, [product]: i };
      });
      acc.push(value);

      return acc;
    }, []);
  }

  displayTariffs(data) {
    const Tariffs = [];
    data.forEach((price, i) => {
      Tariffs.push({
        // DU-1502 - use the tariff matrix identifier as the title
        title: price.parameters["Tariff Matrix Identifier"],
        value: JSON.stringify({ ...price, identifier: uuidv4() }),
        contractDuration: price.parameters.Term,
        active: true,
        rates: [
          this.props.quote.annualgas || this.props.quote.services === 'gas'
            ? {
                name: 'Gas',
                unitRate: price.productPrices[this.state.price[i].gasConsumption].price + ' p/kWh',
                sc: price.productPrices[this.state.price[i].standingCharge].price + ' p/day',
              }
            : {
                name: 'Electricity',
                ...(this.state.price[i].hasOwnProperty('standingChargeElectricity') && {
                  sc: price.productPrices[this.state.price[i].standingChargeElectricity].price + ' p/day',
                }),
                ...(this.state.price[i].hasOwnProperty('dayRate') && {
                  dayRate: price.productPrices[this.state.price[i].dayRate].price + ' p/kWh',
                }),
                ...(this.state.price[i].hasOwnProperty('nightRate') && {
                  nightRate: price.productPrices[this.state.price[i].nightRate].price + ' p/kWh',
                }),
                ...(this.state.price[i].hasOwnProperty('evening/weekendRate') && {
                  eveningWeekendRate: price.productPrices[this.state.price[i]['evening/weekendRate']].price + ' p/kWh',
                }),
                ...(this.state.price[i].hasOwnProperty('singleRate') && {
                  singleRate: price.productPrices[this.state.price[i].singleRate].price + ' p/kWh',
                }),
              },
        ],
      });
    });
    // Sort tariffs by contract duration
    Tariffs.sort((a, b) => {
      // Split contract duration to get the number
      const aDuration = a.contractDuration.split(' ')[0];
      const bDuration = b.contractDuration.split(' ')[0];
      // Convert to number and compare
      return parseInt(aDuration) - parseInt(bDuration);
    });

    return Tariffs;
  }

  async componentDidMount() {
    // Configure array whit allowed rates if is electricity
    const quote = this.props.quote;
    const services = quote.services;
    const res =
      services === 'electricity' &&
      (await this.props.client.query({
        query: OB_regimeConfiguration,
        variables: {
          where: `name = '${quote.supplyAddress.elStandardSettlementConfiguration}'`,
        },
      }));

    const regimeConf =
      res &&
      res.data.OB_regimeConfiguration.length > 0 &&
      res.data.OB_regimeConfiguration[0].mDMTimeRegimeGroupList.map((regime) => {
        return regime.mDMRegisterList[0] && regime.mDMRegisterList[0].product.name;
      });
    const tariffName = quote.tariffName;
    const primaryAddress = quote.primaryAddress;
    const supplyAddress = quote.supplyAddress;
    const primaryAddressLength = primaryAddress ? Object.keys(primaryAddress).length : 0;
    const tariffLength = tariffName && Object.keys(tariffName).length;
    const isRenewal = quote.saleType === 'Renewal';

    const params =
      services === 'gas'
        ? {
            LDZ:
              tariffLength > 0 && tariffName.ldz
                ? tariffName.ldz
                : primaryAddress && primaryAddressLength > 0 && primaryAddress.ldz
                ? primaryAddress.ldz
                : supplyAddress.ldz,
            AQ: isRenewal ? parseInt(this.props.firstStepData.aq) : parseInt(quote.aq) || parseInt(quote.annualgas),
            Date: quote.startDate,
            Month: quote.month,
            SaleType: quote.saleType,
          }
        : {
            GSP:
              tariffLength > 0 && tariffName.gspGroup
                ? tariffName.gspGroup
                : primaryAddress && primaryAddressLength > 0 && primaryAddress.gspGroup
                ? primaryAddress.gspGroup
                : supplyAddress.gspGroup,
            ProfileClass:
              primaryAddress && primaryAddressLength > 0 && primaryAddress.elProfileClass
                ? primaryAddress.elProfileClass
                : supplyAddress.elProfileClass,
            AQ: isRenewal
              ? parseInt(this.props.firstStepData.aq)
              : parseInt(quote.aq) || parseInt(quote.annualelectricity),
            Date: quote.startDate,
            Month: quote.month,
            SaleType: quote.saleType,
          };

    axios
      .post(
        `${
          window.config.consul.TARIFFS_URL ||
          window.TARIFFS_URL ||
          (typeof TARIFFS_URL !== 'undefined' ? TARIFFS_URL : '')
        }/tariffs/`,
        params,
        { headers: { Authorization: 'Bearer ' + localStorage.token } }
      )
      .then((response) => {
        let Tariffs = [];

        response.data.sort((a, b) => a.name.localeCompare(b.name));
        // Date to display if there is a regime and is electricity
        const displayData =
          services === 'electricity' ? this.filterTariffsByRegimeConfig(response.data, regimeConf) : [];
        // If displayData is empty get response data
        const data = this.structureRespByIndex(displayData.length > 0 ? displayData : response.data);
        this.setState({ ...this.state, price: data });
        Tariffs = this.displayTariffs(displayData.length > 0 ? displayData : response.data);
        this.setState({ Tariffs: Tariffs, inputFieldsByRegimeConf: regimeConf, isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  tariffFieldOnChange(fieldName, e) {
    const updatedTariff = {
      ...this.props.value,
      name: this.props.value.name,
      [fieldName]: parseFloat(e.target.value),
      bespokePrice: true,
      // temporary fix for DUS-2173
      // TODO: need to revise whole logic in setTariff()
      ...(fieldName === 'eveningWeekendRate' && {
        ['evening/weekendRate']: parseFloat(e.target.value),
      }),
    };
    this.props.setTariff(updatedTariff);
  }

  render() {
    const isAdmin = this.props.userRoles.some((role) => role.role.key === 'SALESADMIN');
    const AQ_ELECTRICITY_BESPOKE_THRESHOLD = window.config.consul.AQ_ELECTRICITY_BESPOKE_THRESHOLD;
    const AQ_GAS_BESPOKE_THRESHOLD = window.config.consul.AQ_GAS_BESPOKE_THRESHOLD;

    const { quote } = this.props;

    const isBasePriceEditable = this.state.Tariffs.length === 0;
    const inputFieldsByRegimeConf = [...this.state.inputFieldsByRegimeConf];
    return this.state.isLoading ? <Loading /> : (
      <div className={'tariff-boxes ' + this.props.className}>
        {(isAdmin ||
          (quote.services === 'electricity' &&
            (quote.aq || quote.annualelectricity) <= parseInt(AQ_ELECTRICITY_BESPOKE_THRESHOLD)) ||
          isAdmin ||
          (quote.services === 'gas' && (quote.aq || quote.annualgas) <= parseInt(AQ_GAS_BESPOKE_THRESHOLD))) &&
          this.state.Tariffs.map((e) => (
            <div key={e.value} className='tariff-box'>
              <Input
                type='radio'
                onChange={(ev) => {
                  this.props.setTariff(JSON.parse(ev.target.value));
                }}
                name={this.props.name ? this.props.name : 'tariffName'}
                required
                disabled={!e.active}
                id={e.value}
                value={e.value}
                checked={this.props.value.identifier === JSON.parse(e.value).identifier}
                />
              <Label htmlFor={e.value} style={{ cursor: 'pointer' }}>
                <Row>
                  <Col className='col-12 header'>
                    <i className='icon ion-android-radio-button-off' />
                    <i className='icon ion-android-radio-button-on' />
                    <h2>{e.title}</h2>
                  </Col>
                  {e.rates.map((rate) => {
                    return (
                      <Col key={rate.name} className='col-xs-12'>
                        {e.title.toLowerCase().includes('advanced') &&
                          window.config.consul.UPFRONT_PAYMENTS_MSG_ENABLED && (
                            <blockquote style={{ margin: '1em 0 0 0' }}>
                              This is an advanced saver tariff - a payment will be taken shortly after go live date
                            </blockquote>
                          )
                        }
                        <h4>{rate.name}</h4>
                        <table className='rates' key={rate.name}>
                          <tbody>
                            {rate.name === 'Gas' && (
                              <tr>
                                <td>Unit Rate</td>
                                <td>{rate.unitRate}</td>
                              </tr>
                            )}
                            {quote.services === 'electricity' && rate.dayRate !== undefined && (
                              <tr>
                                <td>Day Rate</td>
                                <td>{rate.dayRate}</td>
                              </tr>
                            )}
                            {quote.services === 'electricity' && rate.nightRate !== undefined && (
                              <tr>
                                <td>Night Rate</td>
                                <td>{rate.nightRate}</td>
                              </tr>
                            )}
                            {quote.services === 'electricity' && rate.eveningWeekendRate !== undefined && (
                              <tr>
                                <td>Evening/Weekend Rate Rate</td>
                                <td>{rate.eveningWeekendRate}</td>
                              </tr>
                            )}
                            {quote.services === 'electricity' && rate.singleRate !== undefined && (
                              <tr>
                                <td>Single Rate</td>
                                <td>{rate.singleRate}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Standing Charge</td>
                              <td>{rate.sc}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    );
                  })}
                </Row>
              </Label>
            </div>
        ))}
        {((quote.annualgasIndustry
          ? quote.annualgasIndustry
          : quote.aq || quote.annualelectricity || quote.annualelectricity) >
          parseInt(AQ_ELECTRICITY_BESPOKE_THRESHOLD) ||
          this.props.useBespokePrice ||
          this.global.permissions.includes('Quotes.AlwaysUseBespokePrice') ||
          this.global.permissions.includes('Quotes.AllowBespokePrice')) && (
            <Row>
              <Col className='col-6'>
                {(quote.annualgas || quote.services === 'gas') &&  
                  this.renderTariffInputs(inputFieldsByRegimeConf, quote, this.props)
                }
                {quote.services === 'electricity' && 
                  this.renderTariffInputs(inputFieldsByRegimeConf, quote, this.props)
                }
              </Col>
              {/* Render tariff supplier fields */}
            {isAdmin &&
              <Col className='col-6'>
                {(quote.annualgas || quote.services === 'gas') && 
                  this.renderTariffInputs(inputFieldsByRegimeConf, quote, this.props, 'Supplier ', !isBasePriceEditable)
                }
                {quote.services === 'electricity' && 
                  this.renderTariffInputs(inputFieldsByRegimeConf, quote, this.props, 'Supplier ', !isBasePriceEditable)
                }
              </Col>}
            </Row>
        )}
        {!this.global.permissions.includes('Quotes.AlwaysUseBespokePrice') &&
          quote.services === 'electricity' &&
          (quote.aq || quote.annualelectricity) <= parseInt(AQ_ELECTRICITY_BESPOKE_THRESHOLD) &&
          quote.services === 'gas' &&
          (quote.aq || quote.annualgas) <= parseInt(AQ_GAS_BESPOKE_THRESHOLD) &&
          !this.state.isLoading &&
          (!this.state.Tariffs || this.state.Tariffs.length === 0) &&
          !this.props.userRoles.some((role) => role.role.key === 'SALESADMIN') && (
            <Row>
              <Col className='col-6'>
                <div className='alert alert-danger alert-dismissible fade show'>
                  <button type='button' className='close' data-dismiss='alert'>
                    ×
                  </button>
                  <h4 className='alert-heading'>Missing Tariff!</h4> We could not find tariff for the customer. The
                  Quote cannot be completed.
                </div>
              </Col>
            </Row>
          )}
      </div>
    );
  }
}

export default withApollo(SelectTariffs);
