import React, { Component } from 'reactn';
import FormWizzard from '../../components/FormWizzard/FormWizzard';
import Mpan from '../../components/Mpan/Mpan';
import FindAddress from '../../components/FindAddress/FindAddress';
import SelectBoxes from '../../components/SelectBoxes/SelectBoxes';
import SelectTariffs from '../../components/SelectTariffs/SelectTariffs';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import { graphql, Mutation, withApollo } from 'react-apollo';
import { createUpdateQuote, getQuoteById, getQuotesQuery, ukAddressesDetails } from '../../queries/Queries';
import Loading from '../../components/Loading';
import ReactDOM from 'react-dom';
import ExpiringAlert from '../../components/ExpiringAlert';
import QuoteOverview from '../../components/QuoteOverview';
import i18n from '../Pages/Login/i18n';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { Fragment } from 'react';
import SalesAgentSelect from '../../components/SalesAgentSelect/SalesAgentSelect';
import { isValidEmail, setTariffName, BASE_PRICE_STRINGS } from '../../utils/Helpers';

const QuoteTypes = [
  {
    title: 'Single Meter Point',
    icon: 'icon ion-location',
    value: 'single',
    active: true,
  },
  {
    title: 'Multiple Meter Point',
    icon: 'icon-atom ion-Multiple-Locations',
    value: 'multiple',
    active: false,
  },
].filter(
  ({ value }) =>
    (window.config.consul.QUOTE_TYPES ||
      window.QUOTE_TYPES ||
      (typeof QUOTE_TYPES !== 'undefined' ? QUOTE_TYPES : '')) &&
    (
      window.config.consul.QUOTE_TYPES ||
      window.QUOTE_TYPES ||
      (typeof QUOTE_TYPES !== 'undefined' ? QUOTE_TYPES : '')
    ).includes(value)
);

const QuoteServices = (quoteType) => {
  const quote = [
    {
      title: 'Gas Quote',
      icon: 'icon-atom ion-Gas',
      value: 'gas',
      active: true,
    },
    {
      title: 'Electricity Quote',
      icon: 'icon-atom ion-Electricity',
      value: 'electricity',
      active: true,
    },
    {
      title: 'Dual Quote',
      icon: 'icon-atom ion-Electricity-Gas',
      value: 'dual',
      active: false,
    },
  ];

  return quote.filter(({ value }) => {
    return value.includes(quoteType);
  });
};

const saleTypes = [
  {
    name: 'Acquisition',
    value: 'Acquisition',
  },
  {
    name: 'Switching Form',
    value: 'SwitchingForm',
  },
];

const contractDurations = [
  {
    name: '12 Months',
    value: 12,
  },
  {
    name: '24 Months',
    value: 24,
  },
  {
    name: '36 Months',
    value: 36,
  },
];

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      quoteType: this.props.quote.state.quote.products
        ? this.props.quote.state.quote.products[0].name.toLowerCase()
        : this.props.quote.props.location.quoteType,
    };
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    this.props.quote.state.quote.services = this.state.quoteType;
  }

  isValidated() {
    let validEmail = true;
    if (!document.createquote.dataAgreement.validity.valid) {
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Please check "The client agreed to provide their data"'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );
    }

    if (
      (window.config.consul.SF_INPUTS_ENABLED ||
        window.SF_INPUTS_ENABLED ||
        (typeof SF_INPUTS_ENABLED !== 'undefined' && SF_INPUTS_ENABLED)) &&
      document.createquote.email.value
    ) {
      if (!isValidEmail(document.createquote.email.value)) {
        validEmail = false;
        ReactDOM.render(
          <ExpiringAlert color='danger' message={'Please enter a valid email address'} />,
          document.getElementById('alert').appendChild(document.createElement('div'))
        );
      }
    }

    return (
      validEmail &&
      document.createquote.clientName.validity.valid &&
      document.createquote.companyNumber.validity.valid &&
      document.createquote.dataAgreement.validity.valid &&
      (!this.global.permissions.includes('Quotes.CreateQuoteOnBehalfOfAgent') ||
        (document.createquote.ownerOrganizationId.validity.valid && document.createquote.ownerUserId.validity.valid))
    );
  }
  render() {
    return !this.state.quoteType ? (
      <Redirect to='/quotes' />
    ) : (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Quote Details</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup>
            <Label for='clientName'>{i18n.t('quote.companyName')}</Label>
            <Input
              type='text'
              name='clientName'
              id='clientName'
              autoFocus
              value={this.props.quote.state.quote.clientName}
              maxLength={40}
              onChange={(e) => this.props.onChange(e)}
              placeholder='Enter Company Name'
              disabled={this.props.priceQuote}
              required
              minLength='3'
            />
            <p className='info'>Which client is this quote for?</p>
          </FormGroup>
          {window.config.consul.SF_INPUTS_ENABLED && (
            <Fragment>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for='firstName'>First Name</Label>
                    <Input
                      type='text'
                      name='firstName'
                      id='firstName'
                      value={this.props.quote.state.quote.primaryContact.firstName}
                      onChange={(e) => this.props.onChange(e)}
                      placeholder='Enter First Name'
                      minLength='3'
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup>
                    <Label for='lastName'>Last Name</Label>
                    <Input
                      type='text'
                      name='lastName'
                      id='lastName'
                      value={this.props.quote.state.quote.primaryContact.lastName}
                      onChange={(e) => this.props.onChange(e)}
                      placeholder='Enter Last Name'
                      minLength='3'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for='phone'>Telephone number</Label>
                <Input
                  type='tel'
                  name='phone'
                  id='phone'
                  value={this.props.quote.state.quote.primaryContact.phone}
                  onChange={(e) => this.props.onChange(e)}
                  placeholder='Enter Phone Number'
                  // pattern={'((\\+44(\\s\\(0\\)\\s|\\s0\\s|\\s)?)|0)7\\d{3}(\\s)?\\d{6}'}
                />
              </FormGroup>

              <FormGroup>
                <Label for='email'>E-mail</Label>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  value={this.props.quote.state.quote.primaryContact.email}
                  onChange={(e) => this.props.onChange(e)}
                  placeholder='Enter E-mail'
                />
              </FormGroup>
            </Fragment>
          )}
          <FormGroup>
            <Label for='companyNumber'>{i18n.t('quote.companyNumber')}</Label>
            <Input
              type='text'
              name='companyNumber'
              className='company-number'
              id='companyNumber'
              value={this.props.quote.state.quote.companyNumber}
              onChange={(e) => this.props.onChange(e)}
              disabled={this.props.priceQuote}
              hidden={this.props.quote.state.quote.companyNumberCheckbox}
              pattern={'[\\w]*'}
              title='Only digits and letters are allowed'
              required={!this.props.quote.state.quote.companyNumberCheckbox}
              placeholder='Enter Reference Number'
            />

            <Input
              type='checkbox'
              name='companyNumberCheckbox'
              checked={this.props.quote.state.quote.companyNumberCheckbox}
              onChange={(e) => this.props.onChange(e)}
              id='companyNumberCheckbox'
            />
            <label htmlFor='companyNumberCheckbox' className='css-label'>
              <i className='icon ion-android-checkbox' />
              {i18n.t('quote.companyNumberCheckBox')}
            </label>
          </FormGroup>

          <FormGroup>
            <Input
              type='checkbox'
              name='dataAgreement'
              checked={this.props.quote.state.quote.dataAgreement}
              onChange={(e) => this.props.onChange(e)}
              id='dataAgreement'
              required
              disabled={this.props.priceQuote}
            />
            <label htmlFor='dataAgreement' className='css-label'>
              <i className='icon ion-android-checkbox' />
              {i18n.t('quote.clientDataAgreement')}
            </label>
            {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) && (
              <span>
                <i
                  style={{ fontSize: '16px', padding: '10px', cursor: 'pointer' }}
                  className='icon ion-information-circled'
                  id='Popover1'
                  onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })}
                />
                <Popover
                  trigger='focus'
                  placement='bottom'
                  target='Popover1'
                  isOpen={this.state.popoverOpen}
                  toggle={() => this.setState({ popoverOpen: !this.state.popoverOpen })}
                >
                  <PopoverBody>
                    Under the General Data Protection Act you should be able to prove that your client has given
                    sufficient permission to use their personal data. Industry held data is information which may be
                    held centrally on energy approved databases. The information held can include such information as
                    the business meter serial number or unique meter point reference number. This information in
                    combination with consumption information is considered ‘personal data’ and therefore you should
                    ensure that you have the client’s approval to use that information to generate the quotation. If the
                    client does not consent, then please be aware that your quotation may not be as accurate and
                    therefore may be declined. You may also be in breach of the General Data Protection Act.
                  </PopoverBody>
                </Popover>
              </span>
            )}
          </FormGroup>
          {this.global.permissions.includes('Quotes.CreateQuoteOnBehalfOfAgent') && (
            <div>
              <div>As a Sales admin you can create quotes only on behalf of sales organisations and agents.</div>
              <SalesAgentSelect
                isDisabledInput={this.props.priceQuote}
                // We have to send an object with key "target" and "value" object because onChange expects event that has "target" key (event.target)
                ownerOrgInputOnChange={(selectedBroker) =>
                  this.props.onChange({
                    target: { name: 'ownerOrganizationId', value: selectedBroker.businessPartnerId },
                  })
                }
                salesAgentInputOnChange={this.props.onChange}
              />
            </div>
          )}
          <FormGroup>
            <Label for='salesAgent'>{i18n.t('quote.type')}</Label>
            <SelectBoxes
              disabled={this.props.priceQuote}
              onChange={this.props.onChange}
              name='quotetype'
              options={QuoteTypes}
              className='quotetype'
              value={this.props.quote.state.quote.quotetype}
            />
            {this.props.quote.props.location.quoteType &&
              this.props.quote.props.location.quoteType.includes('electricity') && (
                <p style={{ fontWeight: 'bold', marginTop: '1em' }}>
                  Prices generated only valid for NHH Meters Profile Class 03-04. We do not accept pre-payment meters.
                  For any other meter types please request a bespoke quotation.
                </p>
              )}
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    if (!this.props.quote.state.quote.supplyAddress || !this.props.quote.state.quote.supplyAddress.postalCode) {
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Please select an address'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );
    }

    return (
      document.getElementsByName('createquote')[0].reportValidity() &&
      this.props.quote.state.quote.supplyAddress &&
      !!this.props.quote.state.quote.supplyAddress.postalCode
    );
  }

  componentDidMount() {
    this.props.quote.state.quote.clientName = this.props.quote.state.quote.clientName.trim();
  }

  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Supply Address</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup>
            <FindAddress
              name='supplyAddress'
              address={this.props.quote.state.quote.supplyAddress}
              quoteType={this.props.quote.state.quote.services}
              onChange={(address, isSearched, isManually) =>
                this.props.setAddress('supplyAddress', address, isSearched, isManually)
              }
              resetAddressAndIdentifiers={this.props.resetAddressAndIdentifiers}
              disabled={this.props.priceQuote}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: this.props.quote.props.userRole ? this.props.quote.props.userRole.roles : [],
      quoteType: this.props.quote.state.quote.services,
      toggleRadioBtn:
        this.props.quote.state.quote.searchedMpan || this.props.quote.state.quote.searchedMprn ? true : false,
    };
    this.isValidated = this.isValidated.bind(this);
    this.renderErrorMessages = this.renderErrorMessages.bind(this);
    // state will be empty only when creating a new quote
    // which from the salesportal should default to 'Acquisition'
    // TODO: get rid of props mutation everywhere in the component
    if (!this.props.quote.state.quote.saleType) {
      this.props.quote.state.quote.saleType = 'Acquisition';
    }
  }

  componentDidMount() {
    const isSearched = this.props.quote.state.quote.searchedMpan || this.props.quote.state.quote.searchedMprn;
    const isManual = this.props.quote.state.quote.manualMpan || this.props.quote.state.quote.manualMprn;
    let toggleRadioBtn;

    if (isSearched && !isManual) {
      toggleRadioBtn = true;
    } else if (isSearched && isManual) {
      toggleRadioBtn = false;
    } else if (!isSearched && isManual) {
      toggleRadioBtn = false;
    }

    this.setState({
      ...this.state,
      toggleRadioBtn: toggleRadioBtn,
    });
  }

  renderErrorMessages(message) {
    return ReactDOM.render(
      <ExpiringAlert color='danger' message={message} />,
      document.getElementById('alert').appendChild(document.createElement('div'))
    );
  }

  isValidated() {
    const endDate = this.props.quote.state.quote.endDate;
    const startDate = this.props.quote.state.quote.startDate;
    const validStartAndEndDates =
      !(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) ||
      !endDate ||
      moment(endDate).isBefore(moment(startDate));
    if (!startDate) {
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Please provide Proposed Supply Start Date'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );
    }
    if (!validStartAndEndDates) {
      ReactDOM.render(
        <ExpiringAlert
          color='danger'
          message={'Proposed Supply Start Date must be after Current Supplier Contract End Date'}
        />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );
    }

    !this.props.quote.state.quote.supplyAddress.mprn &&
      this.state.quoteType === 'gas' &&
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Please select MPRN'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );
    !this.props.quote.state.quote.supplyAddress.mpan &&
      this.state.quoteType === 'electricity' &&
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Please select MPAN'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );

    !!this.props.quote.state.quote.annualgas &&
      parseInt(this.props.quote.state.quote.annualgas) < 1000 &&
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Minimal AQ value: 1000'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );
    !!this.props.quote.state.quote.annualelectricity &&
      parseInt(this.props.quote.state.quote.annualelectricity) < 1000 &&
      ReactDOM.render(
        <ExpiringAlert color='danger' message={'Minimal AQ value: 1000'} />,
        document.getElementById('alert').appendChild(document.createElement('div'))
      );

    return this.props.quote.state.quote.services.includes('gas')
      ? !!this.props.quote.state.quote.supplyAddress.mprn &&
          document.createquote.annualgas.validity.valid &&
          validStartAndEndDates &&
          !!startDate &&
          !!this.props.quote.state.quote.annualgas &&
          parseInt(this.props.quote.state.quote.annualgas) >= 1000
      : !!this.props.quote.state.quote.supplyAddress.mpan &&
          document.createquote.annualelectricity.validity.valid &&
          validStartAndEndDates &&
          !!startDate &&
          !!this.props.quote.state.quote.annualelectricity &&
          parseInt(this.props.quote.state.quote.annualelectricity) >= 1000;
  }

  render() {
    const { AQ_ELECTRICITY_BESPOKE_THRESHOLD, AQ_GAS_BESPOKE_THRESHOLD } = window.config.consul;
    const { quote: quoteState } = this.props.quote.state;

    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Select Services</h2>
        </Col>
        <Col className='col-xl-8 col-lg-8 col-md-8 col-sm-12 step-body'>
          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
              <FormGroup>
                <SelectBoxes
                  onChange={this.props.onChange}
                  name='services'
                  options={QuoteServices(this.state.quoteType)}
                  className='services'
                  disabled={this.props.priceQuote}
                  value={quoteState.services}
                />
              </FormGroup>
              {quoteState.services &&
              (window.config.consul.ELECTRICITY_SERVICE ||
                window.ELECTRICITY_SERVICE ||
                (typeof ELECTRICITY_SERVICE !== 'undefined' && ELECTRICITY_SERVICE)) ? (
                quoteState.services.includes('electricity') ? (
                  <p>
                    Prices generated only valid for NHH Meters Profile Class 03-04. We do not accept pre-payment meters.
                    For any other meter types please request a bespoke quotation.
                  </p>
                ) : (
                  <p>
                    Prices generated only valid for meters between U6 to U160. For any other meter types please request
                    a bespoke quotation
                  </p>
                )
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
              {/* GAS FORM */}

              {this.state.quoteType.includes('gas') && (
                <div>
                  <FormGroup>
                    <Label for='mprn'>MPRN</Label>
                    {quoteState.searchedMprn && (
                      <p>
                        <Input
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              toggleRadioBtn: !this.state.toggleRadioBtn,
                            });

                            this.props.quote.setState({
                              quote: {
                                ...quoteState,
                                mprn: quoteState.searchedMprn,
                                manualMprn: null,
                                supplyAddress: {
                                  ...quoteState.searchedAddressType,
                                },
                              },
                            });
                          }}
                          type='radio'
                          name='mprn'
                          id={'mprn_' + quoteState.searchedMprn}
                          value={quoteState.searchedMprn}
                          disabled={this.props.priceQuote}
                          checked={this.state.toggleRadioBtn}
                        />
                        <label htmlFor={'mprn_' + quoteState.searchedMprn} className='css-label'>
                          <i className='icon ion-android-radio-button-off' />
                          <i className='icon ion-android-radio-button-on' />
                          &nbsp; &nbsp;{quoteState.searchedMprn}
                        </label>
                      </p>
                    )}
                    <p>
                      <Input
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            toggleRadioBtn: !this.state.toggleRadioBtn,
                          });

                          this.props.quote.setState({
                            quote: {
                              ...quoteState,
                              mprn: null,
                              supplyAddress: {
                                ...quoteState.supplyAddress,
                                mprn: null,
                                gas_meter_serial_number: null,
                              },
                            },
                          });
                        }}
                        type='radio'
                        name='mprn'
                        id={'mprn_new'}
                        value={'new'}
                        disabled={this.props.priceQuote}
                        checked={!this.state.toggleRadioBtn}
                      />
                      <label htmlFor={'mprn_new'} className='css-label'>
                        <i className='icon ion-android-radio-button-off' />
                        <i className='icon ion-android-radio-button-on' />
                        &nbsp;&nbsp;
                        <span style={{ display: 'inline-block' }}>
                          <Input
                            type='text'
                            name='mprn'
                            id='mprn'
                            value={quoteState.manualMprn || ''}
                            onChange={(e) => {
                              this.props
                                .findMissingAddressProps(e.target.value.replace(/\s/g, ''), 'gas')
                                .catch((error) => this.renderErrorMessages(error));
                            }}
                            placeholder='Enter MPRN number'
                            disabled={this.state.toggleRadioBtn}
                          />
                        </span>
                      </label>
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <Label for='annualgas'>Annual Quantity in kWh (AQ) for Gas</Label>
                    <Input
                      type='number'
                      name='annualgas'
                      id='annualgas'
                      value={quoteState.annualgas}
                      onChange={(e) => this.props.onChange(e)}
                      placeholder='Enter Gas Annual Usage in kWh (AQ)'
                      required
                      valid={
                        this.props.priceQuote ||
                        this.global.permissions.includes('Quotes.AlwaysUseBespokePrice') ||
                        !quoteState.annualgas ||
                        this.state.userRoles.some((role) => role.role.key === 'SALESADMIN') ||
                        quoteState.annualgas <= parseInt(AQ_GAS_BESPOKE_THRESHOLD)
                      }
                    />
                    {!this.props.priceQuote && !this.state.userRoles.some((role) => role.role.key === 'SALESADMIN') && (
                      <FormFeedback style={{ position: 'static' }}>
                        Please request a bespoke price from your utility supplier(s).
                      </FormFeedback>
                    )}
                  </FormGroup>
                  {this.global.permissions.includes('Quotes.StartManualNomination') &&
                    !this.props.priceQuote &&
                    (!quoteState.annualgas || parseInt(quoteState.annualgas) < parseInt(AQ_GAS_BESPOKE_THRESHOLD)) && (
                      <FormGroup>
                        <p>
                          <Input
                            type='checkbox'
                            name='manualNomination'
                            checked={quoteState.manualNomination}
                            onChange={(e) => this.props.onChange(e)}
                            id='manualNomination'
                            disabled={this.props.priceQuote}
                          />
                          <label htmlFor='manualNomination' className='css-label'>
                            <i className='icon ion-android-checkbox' />
                            {i18n.t('quote.startManualNomination')}
                          </label>
                        </p>
                      </FormGroup>
                    )}
                  {quoteState.annualgasIndustry && (
                    <FormGroup>
                      <Label for='annualgasIndustry'>
                        Annual Quantity in kWh (AQ) for Gas Reported by the Industry
                      </Label>
                      <Input
                        type='number'
                        name='annualgasIndustry'
                        id='annualgasIndustry'
                        defaultValue={quoteState.annualgasIndustry}
                        disabled={true}
                      />
                    </FormGroup>
                  )}
                </div>
              )}

              {/* ELECTRICITY FORM */}

              {this.state.quoteType.includes('electricity') && (
                <div>
                  <FormGroup>
                    <Label for='mpan'>MPAN</Label>
                    {quoteState.searchedMpan && (
                      <p>
                        <Input
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              toggleRadioBtn: !this.state.toggleRadioBtn,
                            });

                            this.props.quote.setState({
                              quote: {
                                ...quoteState,
                                mpan: quoteState.searchedMpan,
                                manualMpan: null,
                                supplyAddress: {
                                  ...quoteState.searchedAddressType,
                                },
                              },
                            });
                          }}
                          type='radio'
                          name='mpan'
                          id={'mpan_'}
                          value={quoteState.searchedMpan}
                          disabled={this.props.priceQuote}
                          checked={this.state.toggleRadioBtn}
                        />
                        <label htmlFor={'mpan_'} className='css-label'>
                          <i className='icon ion-android-radio-button-off' />
                          <i className='icon ion-android-radio-button-on' />
                          &nbsp; &nbsp;{quoteState.searchedMpan}
                        </label>
                      </p>
                    )}
                    <p>
                      <Mpan
                        mpan={quoteState.manualMpan || quoteState.searchedMpan || ''}
                        profileClass={
                          this.state.toggleRadioBtn
                            ? quoteState.searchedAddressType.elProfileClass
                            : quoteState.supplyAddress.elProfileClass
                        }
                        lineLossFactor={
                          this.state.toggleRadioBtn
                            ? quoteState.searchedAddressType.elLineLossFactor
                            : quoteState.supplyAddress.elLineLossFactor
                        }
                        meterTimeswitchClass={
                          this.state.toggleRadioBtn
                            ? quoteState.searchedAddressType.elMeterTimeswitchClass
                            : quoteState.supplyAddress.elMeterTimeswitchClass
                        }
                        state={this.props}
                        disabled={this.state.toggleRadioBtn}
                      />
                    </p>

                    <p>
                      <Input
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            toggleRadioBtn: !this.state.toggleRadioBtn,
                          });
                          this.props.quote.setState({
                            quote: {
                              ...quoteState,
                              mpan: null,
                              supplyAddress: {
                                ...quoteState.supplyAddress,
                                mpan: null,
                              },
                            },
                          });
                        }}
                        type='radio'
                        name='mpan'
                        id={'mpan_new'}
                        value={'new'}
                        disabled={this.props.priceQuote}
                        checked={!this.state.toggleRadioBtn}
                      />
                      <label htmlFor={'mpan_new'} className='css-label'>
                        <i className='icon ion-android-radio-button-off' />
                        <i className='icon ion-android-radio-button-on' />
                        &nbsp;&nbsp;
                        <span style={{ display: 'inline-block' }}>
                          <Input
                            type='text'
                            name='mpan'
                            id='mpan'
                            value={quoteState.manualMpan || ''}
                            maxLength={13}
                            onChange={(e) => {
                              const utilityIdentifier = e.target.value.replace(/\s/g, '');
                              this.props
                                .findMissingAddressProps(utilityIdentifier, 'electricity')
                                .catch((error) => this.renderErrorMessages(error));
                            }}
                            placeholder='Enter MPAN number'
                            disabled={this.state.toggleRadioBtn}
                          />
                        </span>
                      </label>
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label for='annualelectricity'>Annual Usage in kWh (EAC) for Electricity</Label>
                    <Input
                      type='number'
                      name='annualelectricity'
                      id='annualelectricity'
                      value={quoteState.annualelectricity}
                      onChange={(e) => this.props.onChange(e)}
                      required
                      placeholder='Annual Usage in kWh (EAC) for Electricity'
                      valid={
                        this.props.priceQuote ||
                        this.global.permissions.includes('Quotes.AlwaysUseBespokePrice') ||
                        !quoteState.annualelectricity ||
                        this.state.userRoles.some((role) => role.role.key === 'SALESADMIN') ||
                        quoteState.annualelectricity <= parseInt(AQ_ELECTRICITY_BESPOKE_THRESHOLD)
                      }
                    />
                    {!this.props.priceQuote && !this.state.userRoles.some((role) => role.role.key === 'SALESADMIN') && (
                      <FormFeedback style={{ position: 'static' }}>
                        Please request a bespoke price from your utility supplier(s).
                      </FormFeedback>
                    )}
                  </FormGroup>

                  {this.global.permissions.includes('Quotes.StartManualNomination') &&
                    !this.props.priceQuote &&
                    (!quoteState.annualgas || parseInt(quoteState.annualgas) < parseInt(AQ_GAS_BESPOKE_THRESHOLD)) && (
                      <FormGroup>
                        <p>
                          <Input
                            type='checkbox'
                            name='manualNomination'
                            checked={quoteState.manualNomination}
                            onChange={(e) => this.props.onChange(e)}
                            id='manualNomination'
                            disabled={this.props.priceQuote}
                          />
                          <label htmlFor='manualNomination' className='css-label'>
                            <i className='icon ion-android-checkbox' />
                            {i18n.t('quote.startManualNomination')}
                          </label>
                        </p>
                      </FormGroup>
                    )}

                  {
                    // HH meter fields - electricity only
                    quoteState.isHHMeter && (
                      <Fragment>
                        <FormGroup>
                          <Label for='agreedCapacity'>kVa Capacity</Label>
                          <Input
                            type='number'
                            name='agreedCapacity'
                            id='agreedCapacity'
                            value={quoteState.agreedCapacity}
                            onChange={(e) => this.props.onChange(e)}
                            required={
                              quoteState.supplyAddress.elMeasurementClass === 'E' ||
                              quoteState.supplyAddress.elMeasurementClass === 'C'
                            }
                            placeholder='kVa Capacity'
                          />
                        </FormGroup>
                        <FormGroup>
                          <p>
                            <Input
                              type='checkbox'
                              name='hasSignedAgentContract'
                              checked={quoteState.hasSignedAgentContract}
                              onChange={(e) => this.props.onChange(e)}
                              id='hasSignedAgentContract'
                            />
                            <label htmlFor='hasSignedAgentContract' className='css-label'>
                              <i className='icon ion-android-checkbox' /> Does the client have a signed contract with a
                              Meter Operator or Data Aggregator/Data Collector?
                            </label>
                          </p>
                        </FormGroup>
                      </Fragment>
                    )
                  }
                </div>
              )}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
              <Label>Proposed Supply Start Date</Label>
              <br />
              <SingleDatePicker
                className={this.state.focusedstartdate ? 'focused' : ''}
                date={quoteState.startDate ? moment(quoteState.startDate) : null} // momentPropTypes.momentObj or null
                numberOfMonths={1}
                onDateChange={(date) => {
                  this.props.quote.setState({
                    quote: {
                      ...quoteState,
                      startDate: date ? date.format('YYYY-MM-DD') : null,
                      month: date.format('MMM-YY'),
                    },
                  });
                }}
                displayFormat='DD/MM/YY'
                openDirection='up'
                showDefaultInputIcon={true}
                isOutsideRange={(day) =>
                  (window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
                  (this.state.userRoles.some((role) => role.role.key === 'SALESADMIN')
                    ? day.isBefore(moment())
                    : day.isBefore(moment().add(9, 'days')) || day.isAfter(moment().add(12, 'months')))
                }
                inputIconPosition='after'
                focused={this.state.focusedstartdate} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focusedstartdate: focused })} // PropTypes.func.isRequired
                id='quote-start-date' // PropTypes.string.isRequired,
              />
            </Col>
          </Row>

          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
              <Label>Current Supplier Contract End Date</Label>
              <br />
              <SingleDatePicker
                className={this.state.focusedEnd ? 'focused' : ''}
                date={quoteState.endDate ? moment(quoteState.endDate) : null} // momentPropTypes.momentObj or null
                numberOfMonths={1}
                onDateChange={(date) => {
                  this.props.onChange({
                    target: {
                      name: 'endDate',
                      value: date ? date.format('YYYY-MM-DD') : null,
                    },
                  });
                }}
                displayFormat='DD/MM/YY'
                openDirection='up'
                focused={this.state.focusedEnd} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })} // PropTypes.func.isRequired
                id='quote-end-date' // PropTypes.string.isRequired,
                showDefaultInputIcon={true}
                isOutsideRange={() => false}
                inputIconPosition='after'
              />
            </Col>
          </Row>

          <Row>
            <Col className='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
              <Label for='saleType'>Sale Type: </Label>
              <Input
                type='select'
                name='saleType'
                id='saleType'
                placeholder='Select Sale Type'
                value={quoteState.saleType}
                onChange={(e) => this.props.onChange(e)}
              >
                {[...saleTypes].map((option, index) => (
                  <option value={option.value} key={index} hidden={quoteState.saleType != option.value}>
                    {option.name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: this.props.quote.props.userRole ? this.props.quote.props.userRole.roles : [],
      advancedPrice: false,
      loading: false,
    };
    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    if (document.createquote.commissiongasUnitRate) {
      if (document.createquote.commissiongasUnitRate.validity.rangeOverflow) {
        document.createquote.commissiongasUnitRate.setCustomValidity(
          'Unfortunately, your commission level is higher than expected. Please contact us to discuss why a higher commission is needed for this contract.'
        );
      } else {
        document.createquote.commissiongasUnitRate.setCustomValidity('');
      }
    }
    if (document.createquote.commissiongasStandingCharge) {
      if (document.createquote.commissiongasStandingCharge.validity.rangeOverflow) {
        document.createquote.commissiongasStandingCharge.setCustomValidity(
          'Unfortunately, your commission level is higher than expected. Please contact us to discuss why a higher commission is needed for this contract.'
        );
      } else {
        document.createquote.commissiongasStandingCharge.setCustomValidity('');
      }
    }

    return this.props.quote.state.quote.services === 'gas'
      ? typeof this.props.quote.state.quote.tariffName !== 'undefined' &&
          (!document.createquote.unitRate || document.createquote.unitRate.validity.valid) &&
          (!document.createquote.sc || document.createquote.sc.validity.valid) &&
          (!document.createquote.commissiongasUnitRate || document.createquote.commissiongasUnitRate.validity.valid) &&
          (!document.createquote.commissiongasStandingCharge ||
            document.createquote.commissiongasStandingCharge.validity.valid) &&
          document.createquote.contractDuration.validity.valid
      : typeof this.props.quote.state.quote.tariffName !== 'undefined' &&
          (!document.createquote.unitRate || document.createquote.unitRate.validity.valid) &&
          (!document.createquote.sc || document.createquote.sc.validity.valid) &&
          (!document.createquote.kVaCharge || document.createquote.kVaCharge.validity.valid) &&
          (!document.createquote.meteringCharge || document.createquote.meteringCharge.validity.valid) &&
          document.createquote.commissionelectricity.validity.valid &&
          document.createquote.commissionelectricityStandingCharge.validity.valid &&
          document.createquote.contractDuration.validity.valid;
  }

  render() {
    const stepCommissionUnitRate =
      window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '') ? 0.01 : 0.001;
    const stepCommissionStandingCharge =
      window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '') ? 1 : 0.001;
    const maxCommissionUnitRate =
      (window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
      !this.global.permissions.includes('Quotes.UnlimitedCommissionUnitRate')
        ? 3
        : Number.MAX_SAFE_INTEGER;
    const maxCommissionStandingCharge =
      (window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
      !this.global.permissions.includes('Quotes.UnlimitedCommissionStandingCharge')
        ? 100
        : Number.MAX_SAFE_INTEGER;

    return (
      //control what to render based on the loading state - render only after setQuery has been called successfully
      this.state.loading ? (
        <Loading />
      ) : (
        <Row className='step'>
          <Col className='col-12 step-header'>
            <h2>Select tariff details</h2>
            {window.config.consul.SMART_METER_MSG_ENABLED && (
              <blockquote style={{ fontSize: '1rem' }}>
                Prices are subject to the installation of a smart meter, if the meter is eligible. Where installation is
                refused we reserve the right to amend contract prices to our Smart Default Rate*
              </blockquote>
            )}
          </Col>
          <Col className='col-12 step-body'>
            <Row>
              <Col className='col-6'>
                {this.props.quote.state.quote.services.includes('gas') &&
                  !(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) && (
                    <FormGroup tag='fieldset'>
                      <Label htmlFor='gastariff'>Select Gas Tariff Options</Label>
                      <br />
                      <p>
                        <Input
                          type='checkbox'
                          name='greengas'
                          checked={this.props.quote.state.quote.greengas}
                          onChange={(e) => this.props.onChange(e)}
                          id='greengas'
                        />
                        <label htmlFor='greengas' className='css-label'>
                          <i className='icon ion-android-checkbox' /> Green Gas
                        </label>
                      </p>
                      <p>
                        <Input
                          type='checkbox'
                          name='amr'
                          checked={this.props.quote.state.quote.amr}
                          onChange={(e) => this.props.onChange(e)}
                          id='amr'
                        />
                        <label htmlFor='amr' className='css-label'>
                          <i className='icon ion-android-checkbox' />
                          AMR
                        </label>
                      </p>
                    </FormGroup>
                  )}
              </Col>
            </Row>
            <Row>
              <Col className='col-12'>
                <SelectTariffs
                  setTariff={this.props.setTariff}
                  value={this.props.quote.state.quote.tariffName}
                  quote={this.props.quote.state.quote}
                  userRoles={this.state.userRoles}
                />
              </Col>
            </Row>
            <Row>
              <Col className='col-6'>
                {this.props.quote.state.quote.services.includes('gas') && (
                  <Fragment>
                    <FormGroup>
                      <Label for='commissiongasUnitRate'>Commission Amount for Unit Rate (p/kWh) *</Label>
                      <Input
                        type='number'
                        step={stepCommissionUnitRate}
                        min='0'
                        max={maxCommissionUnitRate}
                        name='commissiongasUnitRate'
                        id='commissiongasUnitRate'
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.quote.state.quote.commissiongasUnitRate}
                        placeholder='Commission Amount for Unit Rate'
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for='commissiongasStandingCharge'>Commission Amount for Standing Charge (p/day) *</Label>
                      <Input
                        type='number'
                        step={stepCommissionStandingCharge}
                        min='0'
                        max={maxCommissionStandingCharge}
                        name='commissiongasStandingCharge'
                        id='commissiongasStandingCharge'
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.quote.state.quote.commissiongasStandingCharge}
                        placeholder='Commission Amount for Standing Charge'
                        required
                      />
                    </FormGroup>
                  </Fragment>
                )}
                {this.props.quote.state.quote.services.includes('electricity') && (
                  <Fragment>
                    <FormGroup>
                      <Label for='commissionelectricity'>Commission Amount for Electricity (p/kWh) *</Label>
                      <Input
                        type='number'
                        step={stepCommissionUnitRate}
                        min='0'
                        max={maxCommissionUnitRate}
                        name='commissionelectricity'
                        id='commissionelectricity'
                        value={this.props.quote.state.quote.commissionelectricity}
                        onChange={(e) => this.props.onChange(e)}
                        placeholder='Commission Amount for Electricity'
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for='commissionelectricityStandingCharge'>
                        Commission Amount for Electricity Standing Charge (p/day) *
                      </Label>
                      <Input
                        type='number'
                        step={stepCommissionStandingCharge}
                        min='0'
                        max={maxCommissionStandingCharge}
                        name='commissionelectricityStandingCharge'
                        id='commissionelectricityStandingCharge'
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.quote.state.quote.commissionelectricityStandingCharge}
                        placeholder='Commission Amount for Electricity Standing Charge'
                        required
                      />
                    </FormGroup>
                  </Fragment>
                )}
                <FormGroup>
                  <Label for='contractDuration'>Select Contract Duration in months *</Label>
                  <Input
                    disabled={
                      !(
                        this.props.quote.state.userRoles &&
                        this.props.quote.state.userRoles.some((role) => role.role.key === 'SALESADMIN')
                      )
                    }
                    type='select'
                    name='contractDuration'
                    id='contractDuration'
                    placeholder='Select contract duration'
                    value={this.props.quote.state.quote.contractDuration}
                    onChange={(e) => this.props.onChange(e)}
                    required
                  >
                    <option selected={true} value=''>
                      Select contract duration
                    </option>
                    {[...contractDurations].map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for='tariffId'>Tariff Matrix Identifier *</Label>
                  <Input
                    type='text'
                    name='Tariff Matrix Identifier'
                    id='Tariff Matrix Identifier'
                    disabled={
                      !(
                        this.props.quote.state.userRoles &&
                        this.props.quote.state.userRoles.some((role) => role.role.key === 'SALESADMIN')
                      )
                    }
                    value={
                      this.props.quote.state.quote.tariffName.parameters &&
                      this.props.quote.state.quote.tariffName.parameters['Tariff Matrix Identifier']
                    }
                    onChange={(e) => this.props.onChange(e)}
                    placeholder='Tariff Matrix Identifier'
                    required
                  />
                </FormGroup>
                {this.global.permissions.includes('Quotes.BespokeAdvancedPriceCheckbox') &&
                  window.config.consul.UPFRONT_PAYMENTS_MSG_ENABLED && (
                    <Fragment>
                      <Input
                        type='checkbox'
                        name='advancedPrice'
                        id='advancedPrice'
                        className='css-checkbox'
                        checked={this.props.quote.state.advancedPrice}
                        onChange={(e) => {
                          this.props.onChange(e);
                          this.setState({
                            ...this.state,
                            advancedPrice: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor='advancedPrice' className='css-label'>
                        <i className='icon ion-android-checkbox'></i>
                        This is an advanced saver tariff – a payment will be taken on or around go live date
                      </label>
                    </Fragment>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      )
    );
  }
}

class Step5 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
    return true;
  }
  render() {
    const tariff = this.props.quote.state.quote.tariffName;

    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>{tariff.name}</h2>
        </Col>
        <Col className='col-12'>
          <QuoteOverview quote={this.props.quote.state.quote} />
          <Row>
            <Col className='col-6'>
              <FormGroup>
                <Label for='acceptance'>The client accepted the above quote</Label>
                <p>
                  <Input
                    type='checkbox'
                    name='acceptance'
                    checked={this.props.quote.state.quote.acceptance}
                    onChange={(e) => this.props.onChange(e)}
                    id='acceptance'
                  />
                  <label htmlFor='acceptance' className='css-label'>
                    <i className='icon ion-android-checkbox' />
                    Yes, the client accepted it.
                  </label>
                </p>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
class Step6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownOpen: false,
    };
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
    return true;
  }
  render() {
    const paragraph = this.props.successfulQuote ? (
      <p className='text'>Thank you! Your quote is created.</p>
    ) : (
      <p className='text'>There was an error creating your quote.</p>
    );
    const icon = this.props.successfulQuote ? (
      <i className='icon ion-android-checkmark-circle'></i>
    ) : (
      <i className='icon ion-android-warning'></i>
    );
    return (
      <Row className='step'>
        {this.props.loading ? (
          <Loading />
        ) : (
          <div className='done-step'>
            {icon}
            {paragraph}

            <Dropdown
              isOpen={this.state.dropDownOpen}
              className='pull-right'
              color='primary'
              onClick={() => this.setState({ dropDownOpen: !this.state.dropDownOpen })}
            >
              <DropdownToggle className='pull-right' color='primary'>
                <i className='icon ion-plus-round'></i>&nbsp;&nbsp;Add {i18n.t('quotes.newQuote')}
              </DropdownToggle>
              <DropdownMenu>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{ pathname: '/create-quote' }}
                  onClick={() => {
                    this.props.quote.props.location.quoteType = 'gas';
                    this.props.quote.setState({ key: Math.random(), quote: null });
                  }}
                >
                  <DropdownItem>&nbsp;&nbsp;Gas</DropdownItem>
                </Link>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{ pathname: '/create-quote' }}
                  onClick={() => {
                    this.props.quote.props.location.quoteType = 'electricity';
                    this.props.quote.setState({ key: Math.random(), quote: null });
                  }}
                >
                  <DropdownItem>&nbsp;&nbsp;Electricity</DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>
            {/*<Button color={"primary"} onClick={() => this.props.quote.setState({key: Math.random(), quote: null})}> <i className="icon ion-plus-round"></i>&nbsp;&nbsp; Add New Quote </Button>*/}
          </div>
        )}
      </Row>
    );
  }
}

class CreateQuote extends Component {
  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.transformTariffName = this.transformTariffName.bind(this);
    this.findMissingAddressProps = this.findMissingAddressProps.bind(this);
    this.resetAddressAndIdentifiers = this.resetAddressAndIdentifiers.bind(this);
    this.keepSearchAddress = this.keepSearchAddress.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.state = {
      key: Math.random(),
      advancedPrice: false,
      userRoles: this.props.userRole ? this.props.userRole.roles : [],
      successfulQuote: true,
      lastStepLoading: true,
    };
  }

  /**
   * Clear the initial values of the quote state if the user return to step 2 and pick new address button
   * to hide already selected mpan or mprn from the address from the search results. Otherwise, the user will be able to
   * see the same mprn or mpan in step 3.
   */
  resetAddressAndIdentifiers() {
    this.setState({
      ...this.state,
      quote: {
        ...this.state.quote,
        mprn: null,
        mpan: null,
        manualMpan: null,
        manualMprn: null,
        searchedMpan: null,
        searchedMprn: null,
        searchedAddressType: null,
        supplyAddress: {},
      },
    });
  }

  /**
   * Keeps the selected address based on the search criteria.
   *
   * @param {string|null} existing - The existing selected address.
   * @param {string|null} searched - The searched address.
   * @param {boolean} isSearched - Indicates whether the address was searched for.
   * @returns {string|null} - The selected address to be kept or null if the radio button should be hidden.
   */
  keepSearchAddress(existing, searched, isSearched) {
    // In case we search for address in search bar.
    if (isSearched) {
      return searched;
    }
    // In case we have already selected address from search bar
    // and then we switch to manually entered mpan/mprn we need to keep the selected address otherwise we will hide radio button
    else if (existing && !isSearched) {
      return existing;
    }
    // In case we add new address manually and want to hide radio button.
    else {
      return null;
    }
  }

  /**
   * Find missing address properties for the given utility identifier when the user enters mpan or mprn manually.
   * For Electricity: elLineLossFactor, elProfileClass, elMeterTimeswitchClass, elStandardSettlementConfiguration, gspGroup
   * For Gas: ldz, exz
   */
  findMissingAddressProps(utilityIdentifier, utility) {
    // Set the mpan or mprn in the quote state for Input value change.
    this.setState({
      ...this.state,
      quote: {
        ...this.state.quote,
        ...(utility === 'electricity' && {
          mpan: utilityIdentifier,
          manualMpan: utilityIdentifier,
        }),
        ...(utility === 'gas' && {
          mprn: utilityIdentifier,
          manualMprn: utilityIdentifier,
        }),
      },
    });

    // Return a resolved promise if mpan length is less than 13 to avoid unnecessary queries to the server and to avoid errors.
    if (utility === 'electricity' && utilityIdentifier.length < 13) return Promise.resolve();
    if (utility === 'gas' && (utilityIdentifier.length < 6 || utilityIdentifier.length > 11)) return Promise.resolve();

    return this.props.client
      .query({
        query: ukAddressesDetails,
        variables: { searchValue: utilityIdentifier },
      })
      .then(({ data }) => {
        // If no address is found, set new mpan/mprn, gas_meter_serial_number in the supplyAddress object.
        if (!data.uk_addresses || !data.uk_addresses[0]) {
          const meterData = this.state.quote.supplyAddress.meterData;
          const newAddress = {
            ...this.state.quote.supplyAddress,
            ...(utility === 'electricity' && { mpan: utilityIdentifier }),
            ...(utility === 'gas' && {
              mprn: utilityIdentifier,
              gas_meter_serial_number: meterData && meterData[0] ? meterData[0].gas_meter_serial_number : null,
              meterData:
                meterData && meterData.length > 0
                  ? [
                      {
                        ...meterData[0],
                        mprn: utilityIdentifier,
                      },
                    ]
                  : [
                      {
                        mprn: utilityIdentifier,
                        gas_meter_serial_number: null,
                      },
                    ],
            }),
          };
          this.setAddress('supplyAddress', newAddress, false, true);
          return;
        }

        let mprn = null;
        let gas_meter_serial_number = null;
        if (utility === 'gas' && data.uk_addresses[0].meterData) {
          gas_meter_serial_number = data.uk_addresses[0].meterData[0].gas_meter_serial_number;
          mprn = data.uk_addresses[0].meterData[0].mprn;
        }

        const {
          elLineLossFactor,
          elProfileClass,
          elMeterTimeswitchClass,
          elStandardSettlementConfiguration,
          gspGroup,
          mpan,
          ldz,
          meterData,
        } = data.uk_addresses[0];

        const newAddress = {
          ...this.state.quote.supplyAddress,
          mpan: mpan,
          mprn: mprn,
          elLineLossFactor: elLineLossFactor,
          elProfileClass: elProfileClass,
          elMeterTimeswitchClass: elMeterTimeswitchClass,
          elStandardSettlementConfiguration: elStandardSettlementConfiguration,
          gspGroup: gspGroup,
          ldz: ldz,
          meterData: meterData,
          gas_meter_serial_number: gas_meter_serial_number,
        };
        this.setAddress('supplyAddress', newAddress, false, true);
      });
  }

  /**
   * Sets the address for the quote.
   *
   * @param {string} addressType - The type of address (e.g., "billing", "supply").
   * @param {object} address - The address object containing address details.
   * @param {boolean} [isSearched=true/isManually=false] - Keep track between address types when switching between already selected mpan/mprn
   *      from address selected from search bar and manually entered mpan/mprn.
   */
  setAddress(addressType, address, isSearched = true, isManually = false) {
    this.setState({
      ...this.state,
      quote: {
        ...this.state.quote,
        mprn: address.mprn || null,
        mpan: address.mpan || null,
        searchedMpan: this.keepSearchAddress(this.state.quote.searchedMpan, address.mpan, isSearched),
        manualMpan: isManually ? address.mpan : null,
        searchedMprn: this.keepSearchAddress(this.state.quote.searchedMprn, address.mprn, isSearched),
        manualMprn: isManually ? address.mprn : null,
        searchedAddressType: this.keepSearchAddress(this.state.quote.searchedAddressType, address, isSearched),
        [addressType]: address || {},
        // set whether meter is HH when we select address
        ...(this.isHHAllowed() && {
          isHHMeter:
            address &&
            address.elProfileClass !== null &&
            ['00', '01', '02'].includes(address.elProfileClass) &&
            address.elMeasurementClass !== null,
        }),
      },
    });
  }

  handleInput(e) {
    let state = { ...this.state.quote };
    if (e.target.name === '') {
      state['mpan'] = document.createquote.mpan && document.createquote.mpan.value;
    } else if (e.target.type === 'checkbox') {
      e.target.name === 'advancedPrice'
        ? this.setState({ ...this.state, advancedPrice: e.target.checked })
        : (state[e.target.name] = !state[e.target.name]);
    } else if (
      e.target.name === 'Tariff Matrix Identifier' &&
      state.tariffName &&
      state.tariffName.parameters &&
      state.tariffName.parameters['Tariff Matrix Identifier']
    ) {
      state.tariffName.parameters['Tariff Matrix Identifier'] = e.target.value;
    } else if (['firstName', 'lastName', 'phone', 'email'].includes(e.target.name)) {
      state.primaryContact[e.target.name] = e.target.value;
    } else {
      state[e.target.name] = e.target.value;
    }

    this.setState({ quote: state });
  }

  transformTariffName() {
    const tariff = this.state.quote.tariffName;
    const service = this.state.quote.services;
    const advanced = this.state.advancedPrice;

    let newName = setTariffName(tariff, service, advanced, this.state.quote.isHHMeter);
    this.setState({
      ...this.state,
      quote: {
        ...this.state.quote,
        tariffName: {
          ...this.state.quote.tariffName,
          name: newName,
          tariffCode: newName,
        },
      },
    });
  }

  setTariff(tariff) {
    const getTariffSc = (tariff, scKey) => {
      const getBaseStandingCharge = (params) => {
        for (const key in params) {
          if (params.hasOwnProperty(key) && key.toLowerCase().includes(BASE_PRICE_STRINGS.BASE_SC.toLowerCase())) {
            return params[key];
          }
        }
        return null; // Return null if no matching key is found
      };

      return {
        sc: tariff.sc || (tariff.productPrices && tariff.productPrices[scKey] && tariff.productPrices[scKey].price),
        baseSc: tariff.baseSc || (tariff.parameters && getBaseStandingCharge(tariff.parameters)),
      };
    };

    const getRates = (tariff, data) => {
      if (this.state.quote.services === 'gas') {
        return {
          unitRate:
            tariff.unitRate !== undefined
              ? tariff.unitRate
              : tariff.productPrices &&
                tariff.productPrices[data.gasConsumption] &&
                tariff.productPrices[data.gasConsumption].price,
          baseUnitRate:
            tariff.baseUnitRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_UNIT_RATE]),
        };
      } else {
        const rates = {};
        if (
          data.hasOwnProperty('dayRate') &&
          data.hasOwnProperty('nightRate') &&
          data.hasOwnProperty('evening/weekendRate') &&
          !data.hasOwnProperty('singleRate')
        ) {
          rates.dayRate =
            tariff.dayRate ||
            (tariff.productPrices && tariff.productPrices[data.dayRate] && tariff.productPrices[data.dayRate].price);
          rates.baseDayRate =
            tariff.baseDayRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_DAY_RATE]);

          rates.nightRate =
            tariff.nightRate ||
            (tariff.productPrices &&
              tariff.productPrices[data.nightRate] &&
              tariff.productPrices[data.nightRate].price);
          rates.baseNightRate =
            tariff.baseNightRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_NIGHT_RATE]);

          rates.eveningWeekendRate =
            tariff.eveningWeekendRate !== undefined
              ? tariff.eveningWeekendRate
              : tariff.productPrices &&
                tariff.productPrices[data['evening/weekendRate']] &&
                tariff.productPrices[data['evening/weekendRate']].price;
          rates.baseEveningWeekendRate =
            tariff.baseEveningWeekendRate ||
            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_EVENING_WEEKEND_RATE]);
        } else if (
          data.hasOwnProperty('dayRate') &&
          data.hasOwnProperty('nightRate') &&
          !data.hasOwnProperty('evening/weekendRate') &&
          !data.hasOwnProperty('singleRate')
        ) {
          rates.dayRate =
            tariff.dayRate ||
            (tariff.productPrices && tariff.productPrices[data.dayRate] && tariff.productPrices[data.dayRate].price);
          rates.baseDayRate =
            tariff.baseDayRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_DAY_RATE]);

          rates.nightRate =
            tariff.nightRate ||
            (tariff.productPrices &&
              tariff.productPrices[data.nightRate] &&
              tariff.productPrices[data.nightRate].price);
          rates.baseNightRate =
            tariff.baseNightRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_NIGHT_RATE]);
        } else if (
          !data.hasOwnProperty('dayRate') &&
          !data.hasOwnProperty('nightRate') &&
          data.hasOwnProperty('singleRate')
        ) {
          rates.singleRate =
            tariff.singleRate ||
            (tariff.productPrices &&
              tariff.productPrices[data.singleRate] &&
              tariff.productPrices[data.singleRate].price);
          rates.baseSingleRate =
            tariff.baseSingleRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_SINGLE_RATE]);
        } else if (
          !data.hasOwnProperty('dayRate') &&
          !data.hasOwnProperty('nightRate') &&
          data.hasOwnProperty('evening/weekendRate') &&
          !data.hasOwnProperty('singleRate')
        ) {
          rates.eveningWeekendRate =
            tariff.eveningWeekendRate !== undefined
              ? tariff.eveningWeekendRate
              : tariff.productPrices &&
                tariff.productPrices[data['evening/weekendRate']] &&
                tariff.productPrices[data['evening/weekendRate']].price;
          rates.baseEveningWeekendRate =
            tariff.baseEveningWeekendRate ||
            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_EVENING_WEEKEND_RATE]);
        } else if (
          data.hasOwnProperty('dayRate') &&
          !data.hasOwnProperty('nightRate') &&
          data.hasOwnProperty('evening/weekendRate') &&
          !data.hasOwnProperty('singleRate')
        ) {
          rates.dayRate =
            tariff.dayRate ||
            (tariff.productPrices && tariff.productPrices[data.dayRate] && tariff.productPrices[data.dayRate].price);
          rates.baseDayRate =
            tariff.baseDayRate || (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_DAY_RATE]);

          rates.eveningWeekendRate =
            tariff.eveningWeekendRate !== undefined
              ? tariff.eveningWeekendRate
              : tariff.productPrices &&
                tariff.productPrices[data['evening/weekendRate']] &&
                tariff.productPrices[data['evening/weekendRate']].price;
          rates.baseEveningWeekendRate =
            tariff.baseEveningWeekendRate ||
            (tariff.parameters && tariff.parameters[BASE_PRICE_STRINGS.BASE_EVENING_WEEKEND_RATE]);
        }
        if (this.state.quote.isHHMeter && this.state.quote.hasSignedAgentContract) {
          rates.meteringCharge = 0;
        }
        return rates;
      }
    };

    const getTariffParameters = (tariff) => {
      return tariff.parameters
        ? {
            aqLow: tariff.parameters.AQmin,
            aqHigh: tariff.parameters.AQmax,
            billingFrequency: tariff.parameters['Billing Frequency'],
            earliestStartDate: tariff.parameters['Earliest Start date'],
            latestStartDate: tariff.parameters['Latest Start Date'],
            ldz: tariff.parameters.LDZ,
            term: tariff.parameters.Term,
          }
        : {
            aqLow: null,
            aqHigh: null,
            billingFrequency: null,
            earliestStartDate: null,
            latestStartDate: null,
            ldz: null,
            term: null,
          };
    };

    let data = tariff;
    if (!this.props.priceQuote && tariff.productPrices) {
      data = tariff.productPrices.reduce((acc, curr, i) => {
        const product = curr.searchKey
          .split(' ')
          .map((e, index) => (index > 0 ? e.charAt(0).toUpperCase() + e.slice(1) : e))
          .join('');
        return { ...acc, [product]: i };
      }, {});
    }

    const newTariff = {
      ...tariff,
      id: tariff.index + tariff.tariffId,
      ...getTariffSc(
        tariff,
        this.state.quote.services === 'gas' ? data.standingCharge : data.standingChargeElectricity
      ),
      ...getRates(tariff, data),
      brokerId: 'DYCE',
      tariffCode: tariff.name,
      ...getTariffParameters(tariff),
      contractDuration: tariff.parameters ? tariff.parameters.Term : null,
    };

    this.setState((prevState) => ({
      ...prevState,
      quote: {
        ...prevState.quote,
        contractDuration:
          tariff.parameters && tariff.parameters.Term
            ? tariff.parameters.Term.split(' ')[0].toString()
            : prevState.quote.contractDuration,
        tariffName: newTariff,
      },
    }));

    if (this.state.quote.tariffName.hasOwnProperty(false)) {
      delete this.state.quote.tariffName.false;
    }
  }

  // temproray logic to check if conditions for creating a HH quote are met
  // TODO: remove once feature is GA, check should only be for elec service
  isHHAllowed() {
    const isElectricityQuote = this.state.quote.services === 'electricity';

    let isAllowed = false;
    if (isElectricityQuote) {
      // case when role is Sales Admin - can create HH quote for all brokers
      if (this.global.permissions.includes('Quotes.CreateQuoteOnBehalfOfAgent')) {
        isAllowed = true;
      }
      // only specified brokers can create HH quotes if not admins
      else {
        const ownerOrganizationId =
          this.global.ultimateParentOrganization !== null
            ? this.global.ultimateParentOrganization
            : this.global.salesOrganization;
        isAllowed = window.config.consul.HH_METERS_BROKER_IDS.includes(ownerOrganizationId);
      }
    }

    return isAllowed;
  }

  render() {
    const { AQ_ELECTRICITY_BESPOKE_THRESHOLD, AQ_GAS_BESPOKE_THRESHOLD } = window.config.consul;
    document
      .querySelectorAll('input[type="number"]')
      .forEach((target) => target.addEventListener('wheel', (e) => e.target.blur()));

    if (!this.state.quote) {
      if (this.props.match.params.quoteId) {
        if (!this.props.getQuote.loading && this.props.getQuote.getQuote) {
          let { id, extraData, ...quote } = { ...this.props.getQuote.getQuote };
          if (quote && quote.products) {
            quote.services =
              quote.products.length > 0
                ? quote.products.filter(({ name }) => name === 'Electricity').length > 0
                  ? 'electricity'
                  : '' + quote.products.filter(({ name }) => name === 'Gas').length > 0
                  ? 'gas'
                  : ''
                : QuoteServices(this.state.quoteType).length === 1
                ? QuoteServices(this.state.quoteType)[0].value
                : '';
            quote = quote.products.reduce((acc, curr) => {
              let {
                name,
                commission,
                commissionUnitRate,
                commissionStandingCharge,
                commissionElectrycityStandingCharge,
                meterPoint,
                annualQuantity,
                annualQuantityIndustry,
                greenEnergy,
                greenGas,
                amr,
              } = curr;
              return {
                ...acc,
                commissionelectricity: commission,
                commissionElectrycityStandingCharge: commissionElectrycityStandingCharge,
                commissiongasUnitRate: commissionUnitRate,
                commissiongasStandingCharge: commissionStandingCharge,
                [name === 'Gas' ? 'mprn' : 'mpan']: meterPoint,
                ['annual' + name.toLowerCase()]: annualQuantity,
                ['annual' + name.toLowerCase() + 'Industry']: annualQuantityIndustry,
                greengas: greenGas,
                greenenergy: greenEnergy,
                amr: amr,
              };
            }, quote);
            quote.quotetype =
              QuoteTypes.length === 1 ? QuoteTypes[0].value : quote.products.length === 1 ? 'single' : 'multiple';
          } else {
            quote = {};
            quote.services =
              QuoteServices(this.state.quoteType).length === 1 ? QuoteServices(this.state.quoteType)[0].value : '';
            quote.quotetype = QuoteTypes.length === 1 ? QuoteTypes[0].value : '';
          }
          if (!quote.tariffName) {
            quote.tariffName = {};
          }
          quote = { ...JSON.parse(extraData), ...quote };
          if (this.props.priceQuote) {
            quote.id = id;
            quote.isHHMeter = Object.hasOwn(quote, 'agreedCapacity') && Object.hasOwn(quote, 'hasSignedAgentContract');
          } else {
            quote.sourceQuoteId = id;
          }
          quote.supplyAddress = quote.supplyAddress
            ? quote.supplyAddress
            : {
                city: null,
                street: null,
                dependentStreet: null,
                block: null,
                houseNumber: null,
                postalCode: null,
              };
          this.setState({ quote: quote });
        }
      } else {
        this.setState({
          quote: {
            clientName: '',
            companyNumber: '',
            mprn: '',
            mpan: '',
            services:
              QuoteServices(this.state.quoteType).length === 1 ? QuoteServices(this.state.quoteType)[0].value : '',
            quotetype: QuoteTypes.length === 1 ? QuoteTypes[0].value : '',
            primaryContact: {},
            supplyAddress: {},
            tariffName: {},
          },
        });
      }
      return <Loading />;
    } else {
      let {
        id,
        quotetype,
        acceptance,
        mpan,
        mprn,
        annualelectricity,
        annualgas,
        annualgasIndustry,
        commissionelectricity,
        commissionelectricityStandingCharge,
        commissiongasUnitRate,
        commissiongasStandingCharge,
        services,
        amr,
        greenenergy,
        greengas,
        isHHMeter,
        ...quote
      } = this.state.quote;

      if (services === 'gas') {
        quote.products = [
          {
            name: 'Gas',
            commissionUnitRate: parseFloat(commissiongasUnitRate) || 0,
            commissionStandingCharge: parseFloat(commissiongasStandingCharge) || 0,
            meterPoint: mprn,
            annualQuantity: parseFloat(annualgas),
            annualQuantityIndustry: parseFloat(annualgasIndustry),
            greenGas: greengas,
            amr: amr,
          },
        ];
      } else if (services === 'electricity') {
        quote.products = [
          {
            name: 'Electricity',
            commission: parseFloat(commissionelectricity),
            commissionelectricityStandingCharge: parseFloat(commissionelectricityStandingCharge),
            meterPoint: mpan,
            annualQuantity: parseFloat(annualelectricity),
            greenEnergy: greenenergy,
          },
        ];
      } else if (services === 'electricitygas') {
        quote.products = [
          {
            name: 'Electricity',
            commission: parseFloat(commissionelectricity),
            commissionelectricityStandingCharge: parseFloat(commissionelectricityStandingCharge),
            meterPoint: mpan,
            annualQuantity: parseFloat(annualelectricity),
            greenEnergy: greenenergy,
          },
          {
            name: 'Gas',
            commissionUnitRate: parseFloat(commissiongasUnitRate),
            commissionStandingCharge: parseFloat(commissiongasStandingCharge),
            meterPoint: mprn,
            annualQuantity: parseFloat(annualgas),
            annualQuantityIndustry: parseFloat(annualgasIndustry),
            greenGas: greengas,
            amr: amr,
          },
        ];
      }

      if (quote.contractDuration) {
        quote.contractDuration = parseInt(quote.contractDuration);
      }
      quote.ownerOrganizationId = quote.ownerOrganizationId
        ? quote.ownerOrganizationId
        : this.global.ultimateParentOrganization !== null
        ? this.global.ultimateParentOrganization
        : this.global.salesOrganization;
      quote.subOwnerOrganizationId = quote.subOwnerOrganizationId
        ? quote.subOwnerOrganizationId
        : this.global.ultimateParentOrganization !== null
        ? this.global.salesOrganization
        : null;
      quote.ownerUserId = quote.ownerUserId ? quote.ownerUserId : this.global.username;

      // hasSignedAgentContract is not initialized in state unless checked first
      // so in that case we need to explicity set it to false for the req payload
      if (isHHMeter) {
        quote.hasSignedAgentContract = quote.hasSignedAgentContract || false;
        quote.agreedCapacity = parseInt(quote.agreedCapacity) || 0;
      }
      // delete HH properties if switching between steps
      else {
        delete quote.agreedCapacity;
        delete quote.hasSignedAgentContract;
      }

      let { clientName, ownerOrganizationId, subOwnerOrganizationId, ownerUserId, startDate, endDate, ...extraData } =
        quote;

      const status = acceptance
        ? { key: 'ACCEPTED' }
        : extraData.tariffName.name
        ? { key: 'DRAFT' }
        : { key: 'PENDING_PRICING' };

      const quoteFinal = {
        id,
        clientName,
        ownerOrganizationId,
        subOwnerOrganizationId,
        ownerUserId,
        status,
        startDate,
        endDate,
        extraData: JSON.stringify(extraData),
      };

      let steps = [];

      steps = steps.concat([
        {
          name: i18n.t('quote.start'),
          component: <Step1 quote={this} onChange={this.handleInput} priceQuote={this.props.priceQuote} />,
        },
      ]);

      const siteStep = {
        name: i18n.t('quote.site'),
        component: (
          <Step2
            quote={this}
            priceQuote={this.props.priceQuote}
            setAddress={this.setAddress}
            resetAddressAndIdentifiers={this.resetAddressAndIdentifiers}
          />
        ),
      };
      const servicesStep = {
        name: i18n.t('quote.quoteServices'),
        component: (
          <Step3
            quote={this}
            onChange={this.handleInput}
            findMissingAddressProps={this.findMissingAddressProps}
            priceQuote={this.props.priceQuote}
            setAddress={this.setAddress}
          />
        ),
      };
      steps = steps.concat([siteStep, servicesStep]);

      const hasSalesAdminRole = this.state.userRoles.some((role) => role.role.key === 'SALESADMIN');
      if (
        (isHHMeter && hasSalesAdminRole) ||
        (!isHHMeter && // proceed to bespoke quote if meter is HH and role isn't sales admin
          (this.global.permissions.includes('Quotes.AlwaysUseBespokePrice') ||
            this.props.priceQuote ||
            (!annualgas && !annualelectricity) ||
            (annualgas && annualgas <= parseInt(AQ_GAS_BESPOKE_THRESHOLD)) ||
            (annualelectricity && annualelectricity <= parseInt(AQ_ELECTRICITY_BESPOKE_THRESHOLD)) ||
            (hasSalesAdminRole && !quote.manualNomination)))
      ) {
        steps = steps.concat([
          {
            name: i18n.t('quote.tariff'),
            component: (
              <Step4
                quote={this}
                setQuote={(quote, callback) => this.setState(...this.state, { quote: quote }, callback)}
                bespokePrice={
                  (isHHMeter && hasSalesAdminRole) ||
                  (!isHHMeter &&
                    ((annualgas && annualgas >= parseInt(AQ_GAS_BESPOKE_THRESHOLD)) ||
                      (annualelectricity && annualelectricity >= parseInt(AQ_ELECTRICITY_BESPOKE_THRESHOLD))))
                }
                onChange={this.handleInput}
                setTariff={(tariff) => this.setTariff(tariff)}
              />
            ),
          },
          {
            name: i18n.t('quote.overview'),
            component: <Step5 quote={this} onChange={this.handleInput} />,
          },
        ]);
      }

      steps = steps.concat([
        {
          name: i18n.t('quote.done'),
          component: (
            <Step6
              quote={this}
              onChange={this.handleInput}
              loading={this.state.lastStepLoading}
              successfulQuote={this.state.successfulQuote}
            />
          ),
        },
      ]);

      return (
        <div className='' key={this.state.key}>
          <Row className='subheader'>
            <Col className='col-8'>
              <h1>Get a Quote</h1>
            </Col>
          </Row>
          <Form
            name='createquote'
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <Mutation
              mutation={createUpdateQuote}
              variables={{ quote: quoteFinal }}
              refetchQueries={[
                { query: getQuotesQuery },
                { query: getQuotesQuery, variables: { organizationId: ownerOrganizationId, username: null } },
                {
                  query: getQuotesQuery,
                  variables: { organizationId: ownerOrganizationId, username: this.global.username },
                },
              ]}
            >
              {(createQuote, { data }) => (
                <FormWizzard
                  steps={steps}
                  startAtStep={this.props.priceQuote ? 2 : 0}
                  onLastStep={() => {
                    this.transformTariffName();
                    this.setState({
                      ...this.state,
                      lastStepLoading: true,
                    });
                    createQuote()
                      .then((response) => {
                        this.setState({
                          ...this.state,
                          successfulQuote: true,
                          lastStepLoading: false,
                        });
                      })
                      .catch((errors) => {
                        this.setState({
                          ...this.state,
                          successfulQuote: false,
                          lastStepLoading: false,
                        });
                      });
                  }}
                />
              )}
            </Mutation>
          </Form>
        </div>
      );
    }
  }
}

export default graphql(getQuoteById, {
  name: 'getQuote',
  options: (props) => ({
    variables: { id: parseInt(props.match.params.quoteId) },
  }),
})(withApollo(CreateQuote));
